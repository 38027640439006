import React from "react";
import { Link } from "react-router-dom";
import { RiKakaoTalkFill } from "react-icons/ri";
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const ContactFooter = () => {
  const { t } = useTranslation();
  return (
    <div className="contact-footer bg-theme-dark text-theme-white">
      <div className="main-container pt-28 pb-24">
        <div className="flex flex-col lg:flex-row lg:justify-between gap-y-16 gap-x-16">
          <div className="footer-text lg:w-1/2 max-w-[335px] lg:max-w-[675px]">
            <div className="theme-h3 pb-7">{t("about_ideamood")}</div>
            <div className="theme-p2">{t("footer_text")}</div>
          </div>
          <div className="footer-text lg:w-1/2">
            <div className="theme-h3 pb-7">Social Media</div>
            <div className="flex gap-x-5 lg:gap-x-7 text-gray-400 items-center text-xl lg:text-3xl">
              <a href="http://pf.kakao.com/_NDxjrxj" className="cursor-pointer">
                <RiKakaoTalkFill />
              </a>
              <a
                href="https://www.linkedin.com/company/ideamood/"
                className="cursor-pointer"
              >
                <AiFillLinkedin />
              </a>
              <a href="" className="cursor-pointer">
                <AiFillFacebook />
              </a>
              <a
                href="https://instagram.com/ideamoodcokr?igshid=YmMyMTA2M2Y="
                className="cursor-pointer"
              >
                <AiFillInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container text-gray-400 text-base lg:text-xl pb-8 text-center">
        © 2023, Idea Mood
      </div>
    </div>
  );
};

export default ContactFooter;
