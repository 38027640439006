import React from 'react'

const DesignGraphic = () => {
  return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="100%"
          fill="none"
          viewBox="0 0 713 558"
        >
          <path
            fill="#5C7479"
            d="M110.8 163.535c0 11.067-2.333 20.267-7 27.6-4.667 7.333-10.733 12.733-18.2 16.2-7.467 3.467-15.467 5.2-24 5.2s-16.6-1.733-24.2-5.2c-7.467-3.467-13.533-8.867-18.2-16.2-4.667-7.333-7-16.533-7-27.6v-50.6c0-10.933 2.333-20 7-27.2 4.667-7.2 10.733-12.467 18.2-15.8 7.6-3.467 15.667-5.2 24.2-5.2s16.533 1.733 24 5.2c7.467 3.333 13.533 8.6 18.2 15.8 4.667 7.2 7 16.267 7 27.2v50.6zm-75.2.8c0 9.2 2.533 15.8 7.6 19.8 5.067 3.867 11.2 5.8 18.4 5.8 7.2 0 13.267-1.933 18.2-5.8 5.067-4 7.6-10.6 7.6-19.8v-51c0-9.333-2.533-16-7.6-20-4.933-4-11-6-18.2-6-7.2 0-13.333 2-18.4 6-5.067 4-7.6 10.667-7.6 20v51zm97.647 45.8c-1.867 0-2.8-1-2.8-3v-12c0-1.467.467-2.667 1.4-3.6l40.6-42.2c9.333-9.733 16.267-17.8 20.8-24.2 4.533-6.533 6.8-13 6.8-19.4 0-5.333-1.733-9.8-5.2-13.4-3.467-3.733-8.733-5.6-15.8-5.6-6.533 0-12.333 1.467-17.4 4.4-4.933 2.933-9.8 6.8-14.6 11.6-.667.667-1.333 1-2 1-.8 0-1.467-.4-2-1.2l-9.4-12.8c-.533-.8-.8-1.4-.8-1.8 0-.533.333-1.267 1-2.2 5.6-6.133 12-11.133 19.2-15 7.333-4 16-6 26-6 13.2 0 23.933 3.533 32.2 10.6 8.4 7.067 12.6 17.067 12.6 30 0 8.667-2.267 16.867-6.8 24.6-4.4 7.733-11.4 17.067-21 28-8.133 9.333-18.067 19.533-29.8 30.6h56.8c2 0 3 1 3 3v15.6c0 2-1 3-3 3h-89.8z"
          ></path>
          <mask
            id="mask0_542_1671"
            style={{ maskType: "luminance" }}
            width="493"
            height="495"
            x="220"
            y="63"
            maskUnits="userSpaceOnUse"
          >
            <path fill="#fff" d="M220 63h493v494.311H220V63z"></path>
          </mask>
          <g mask="url(#mask0_542_1671)">
            <path
              fill="#FDE268"
              fillOpacity="0.298"
              d="M534.086 89.245C561.405 99.82 677.969 144.92 706.609 253.69 739.264 377.7 641.6 523.72 506.584 550.626c-140.059 27.905-298.258-37.7-285.832-139.335 5.667-46.365 68.714-60.405 65.798-115.015-2.852-53.298-69.869-79.425-62.854-142.856.44-3.98 1.861-15.911 7.841-27.842 33.967-67.769 187.772-80.745 302.549-36.333z"
            ></path>
          </g>
          <path
            fill="#fff"
            d="M344.836 321.293h269.821c8.152 0 12.838-6.621 10.463-14.792l-51.95-178.666c-2.375-8.171-10.922-14.792-19.075-14.792h-269.82c-8.152 0-12.839 6.621-10.454 14.792l51.941 178.666c2.375 8.171 10.922 14.792 19.074 14.792z"
          ></path>
          <path
            fill="#5C7479"
            d="M554.096 113.932c7.776 0 15.947 6.346 18.221 14.15l51.95 178.667c1.101 3.796.642 7.3-1.293 9.895-1.815 2.421-4.777 3.76-8.317 3.76h-269.83c-7.767 0-15.938-6.346-18.203-14.15l-51.959-178.667c-1.101-3.787-.642-7.3 1.293-9.895 1.815-2.421 4.777-3.76 8.317-3.76h269.821zm-209.269 208.26h269.83c4.117 0 7.584-1.596 9.739-4.484 2.283-3.045 2.843-7.117 1.586-11.454l-51.959-178.667c-2.476-8.519-11.417-15.434-19.927-15.434H284.275c-4.117 0-7.575 1.587-9.739 4.475-2.283 3.036-2.843 7.108-1.577 11.454l51.95 178.667c2.476 8.51 11.417 15.443 19.918 15.443z"
          ></path>
          <path
            fill="#D2DBAA"
            fillOpacity="0.298"
            d="M336.06 136.831h14.884c2.595 0 5.319 2.109 6.08 4.713l4.337 14.911c.752 2.605-.733 4.714-3.338 4.714H343.14c-2.605 0-5.319-2.109-6.08-4.714l-4.338-14.911c-.752-2.604.734-4.713 3.338-4.713zM305 136.831h14.884c2.595 0 5.31 2.109 6.08 4.713l4.328 14.911c.761 2.605-.733 4.714-3.338 4.714h-14.883c-2.595 0-5.319-2.109-6.08-4.714l-4.338-14.911c-.752-2.604.734-4.713 3.347-4.713zM345.974 170.917h14.883c2.595 0 5.319 2.109 6.08 4.705l4.329 14.92c.761 2.604-.734 4.713-3.338 4.713h-14.875c-2.604 0-5.328-2.109-6.08-4.713l-4.337-14.92c-.762-2.596.733-4.705 3.338-4.705zM314.904 170.917h14.884c2.595 0 5.328 2.109 6.08 4.714l4.337 14.911c.762 2.604-.733 4.713-3.338 4.713h-14.874c-2.613 0-5.328-2.109-6.089-4.713l-4.338-14.911c-.752-2.605.743-4.714 3.338-4.714z"
          ></path>
          <path
            fill="#CADDFF"
            d="M355.878 204.994h14.883c2.605 0 5.319 2.109 6.08 4.714l4.338 14.911c.761 2.604-.743 4.723-3.338 4.723h-14.884c-2.595 0-5.328-2.119-6.08-4.723l-4.328-14.911c-.761-2.605.733-4.714 3.329-4.714zM324.817 204.994h14.884c2.604 0 5.319 2.109 6.08 4.714l4.338 14.911c.761 2.613-.743 4.723-3.338 4.723h-14.884c-2.604 0-5.319-2.11-6.08-4.723l-4.328-14.911c-.762-2.605.733-4.714 3.328-4.714zM365.791 239.072h14.884c2.604 0 5.318 2.118 6.08 4.722l4.337 14.911c.752 2.605-.733 4.714-3.338 4.714h-14.883c-2.596 0-5.319-2.109-6.071-4.714l-4.347-14.911c-.752-2.604.743-4.722 3.338-4.722zM334.731 239.081h14.874c2.614 0 5.328 2.109 6.089 4.713l4.338 14.911c.752 2.605-.743 4.714-3.338 4.714H341.81c-2.604 0-5.328-2.109-6.08-4.714l-4.337-14.911c-.762-2.604.733-4.713 3.338-4.713zM375.704 273.158h14.883c2.596 0 5.319 2.109 6.08 4.722l4.329 14.902c.77 2.605-.734 4.723-3.329 4.723h-14.884c-2.604 0-5.318-2.118-6.08-4.723l-4.337-14.902c-.752-2.613.733-4.722 3.338-4.722zM344.635 273.149h14.893c2.586 0 5.318 2.118 6.07 4.732l4.338 14.901c.761 2.605-.734 4.714-3.338 4.714h-14.884c-2.604 0-5.318-2.109-6.079-4.714l-4.338-14.901c-.752-2.614.743-4.732 3.338-4.732z"
          ></path>
          <path
            fill="#D2DBAA"
            d="M420.639 297.505H595.72l-46.723-160.674H373.916l46.723 160.674z"
          ></path>
          <path
            fill="#F2F4E6"
            d="M411.579 223.032l11.298-15.929c3.906-5.502 12.114-6.08 18.295-1.284l44.687 34.719c3.897 3.036 9.097 2.678 11.573-.807l10.041-14.168c3.898-5.493 12.115-6.061 18.295-1.265l46.586 36.186 7.602 26.136H430.075l-18.496-63.588z"
          ></path>
          <path
            fill="#5C7479"
            d="M564.861 255.56a.886.886 0 00.697-.339.9.9 0 00-.156-1.257l-38.781-30.134c-3.54-2.751-7.887-3.98-11.94-3.383-3.365.504-6.245 2.237-8.125 4.878l-9.95 14.04c-.908 1.275-2.302 2.109-3.943 2.348-2.045.302-4.273-.34-6.108-1.752l-44.522-34.6c-3.54-2.742-7.895-3.98-11.94-3.374-3.365.495-6.254 2.219-8.134 4.869l-2.641 3.732a.905.905 0 00.211 1.248.893.893 0 001.238-.211l2.65-3.733c1.596-2.246 4.063-3.723 6.933-4.145 3.577-.532 7.428.578 10.583 3.026l44.54 34.591c2.201 1.724 4.925 2.485 7.456 2.119 2.118-.321 3.943-1.413 5.126-3.091l9.95-14.031c1.596-2.246 4.053-3.723 6.933-4.145 3.567-.531 7.428.578 10.592 3.027l38.781 30.134a.924.924 0 00.55.183z"
          ></path>
          <path
            fill="#F2F4E6"
            d="M490.141 180.491c3.182 10.922 14.599 19.771 25.494 19.771 10.903 0 17.167-8.849 13.994-19.771-3.173-10.922-14.59-19.771-25.494-19.771-10.903 0-17.167 8.849-13.994 19.771z"
          ></path>
          <path
            fill="#5C7479"
            d="M515.635 201.152c5.41 0 9.95-2.073 12.783-5.86 2.99-3.989 3.724-9.345 2.064-15.049-3.283-11.261-15.095-20.422-26.347-20.422a.897.897 0 00-.889.898c0 .487.403.89.889.89 10.509 0 21.569 8.584 24.641 19.129 1.495 5.163.862 9.95-1.779 13.49-2.494 3.32-6.529 5.145-11.362 5.145-5.236 0-10.729-2.091-15.48-5.906a.88.88 0 00-1.247.138.907.907 0 00.138 1.256c5.062 4.062 10.958 6.291 16.589 6.291z"
          ></path>
          <path
            fill="#fff"
            d="M238.717 186.222h210.396c7.795 0 15.947 6.337 18.221 14.141l41.221 141.765c2.265 7.804-2.201 14.123-9.995 14.123H288.154c-7.795 0-15.947-6.319-18.221-14.123l-41.221-141.765c-2.265-7.804 2.21-14.141 10.005-14.141z"
          ></path>
          <path
            fill="#5C7479"
            d="M449.113 187.112c7.409 0 15.195 6.053 17.359 13.499l41.221 141.756c1.055 3.613.615 6.951-1.229 9.418-1.733 2.311-4.53 3.576-7.914 3.576H288.145c-7.41 0-15.195-6.052-17.35-13.489l-41.23-141.775c-1.055-3.594-.615-6.942 1.228-9.399 1.734-2.311 4.54-3.586 7.914-3.586h210.406zM288.145 357.14H498.55c3.953 0 7.272-1.522 9.345-4.292 2.183-2.916 2.724-6.813 1.513-10.976l-41.221-141.775c-2.366-8.134-10.922-14.764-19.074-14.764H238.707c-3.952 0-7.272 1.522-9.344 4.292-2.183 2.925-2.715 6.823-1.513 10.986l41.23 141.756c2.357 8.152 10.912 14.773 19.065 14.773z"
          ></path>
          <path
            fill="#5C7479"
            d="M468.903 347.089a.704.704 0 00.238-.037.89.89 0 00.615-1.1l-43.597-149.918a.885.885 0 00-1.1-.605.91.91 0 00-.614 1.11l43.605 149.908a.88.88 0 00.853.642z"
          ></path>
          <path
            fill="#0C2933"
            d="M291.483 331.344h156.538c2.522 0 3.971-2.054 3.238-4.576L418.96 215.715c-.733-2.531-3.374-4.576-5.896-4.576H256.525c-2.521 0-3.97 2.045-3.228 4.576l32.28 111.053c.743 2.522 3.384 4.576 5.906 4.576z"
          ></path>
          <path
            fill="#5C7479"
            d="M463.208 258.054c7.373 0 15.131 6.025 17.286 13.435 1.045 3.595.605 6.923-1.229 9.372-1.715 2.302-4.521 3.567-7.877 3.567-7.382 0-15.14-6.025-17.295-13.434-1.046-3.595-.606-6.924 1.228-9.373 1.724-2.301 4.521-3.567 7.887-3.567zm8.18 28.162c3.934 0 7.235-1.522 9.308-4.282 2.173-2.916 2.705-6.795 1.513-10.94-2.366-8.116-10.894-14.728-19.001-14.728-3.943 0-7.245 1.522-9.317 4.282-2.174 2.907-2.715 6.796-1.504 10.941 2.357 8.116 10.885 14.727 19.001 14.727z"
          ></path>
          <path
            fill="#5C7479"
            d="M469.884 281.053c2.604 0 4.787-1.009 6.163-2.843 1.439-1.925 1.797-4.493 1.008-7.217-1.531-5.282-7.079-9.574-12.361-9.574-2.596 0-4.787 1.018-6.154 2.843-1.449 1.935-1.806 4.494-1.008 7.227.174.614.412 1.228.706 1.824a.879.879 0 001.183.413.899.899 0 00.421-1.192 9.41 9.41 0 01-.596-1.541c-.642-2.182-.376-4.191.725-5.658 1.018-1.375 2.705-2.118 4.723-2.118 4.548 0 9.326 3.705 10.656 8.272.632 2.173.376 4.181-.734 5.648-1.018 1.376-2.705 2.137-4.732 2.137a.89.89 0 000 1.779zM461.227 222.693c.083 0 .165-.018.257-.037a.888.888 0 00.596-1.109l-3.063-10.546c-.789-2.724-3.549-4.851-6.291-4.851h-8.207a.886.886 0 00-.89.898c0 .486.394.881.89.881h8.207c1.926 0 4.017 1.632 4.585 3.576l3.063 10.537a.9.9 0 00.853.651zM456.715 249.874h8.217c1.375 0 2.54-.55 3.283-1.532.761-1.017.963-2.411.55-3.824a.89.89 0 10-1.706.505c.248.871.156 1.678-.275 2.247-.394.531-1.054.816-1.852.816h-8.217c-1.916 0-4.016-1.633-4.585-3.568l-6.676-22.971a.9.9 0 00-1.1-.615c-.477.147-.752.642-.606 1.11l6.676 22.981c.789 2.714 3.558 4.851 6.291 4.851zM448.801 222.693h8.226a.897.897 0 00.889-.898.89.89 0 00-.889-.89h-8.226a.89.89 0 00-.89.89c0 .495.404.898.89.898z"
          ></path>
          <path
            fill="#5C7479"
            d="M451.121 230.626h8.208a.892.892 0 00.889-.899.892.892 0 00-.889-.899h-8.208a.895.895 0 00-.899.899c0 .495.395.899.899.899zM481.851 336.333h8.217c.486 0 .89-.404.89-.89a.885.885 0 00-.89-.889h-8.217c-1.916 0-4.007-1.642-4.567-3.577l-1.843-6.318a.89.89 0 00-1.1-.605.883.883 0 00-.605 1.1l1.834 6.318c.788 2.724 3.549 4.861 6.281 4.861zM490.756 324.218c.073 0 .156-.018.247-.036a.896.896 0 00.606-1.11l-7.447-25.613c-.797-2.714-3.558-4.851-6.281-4.851h-8.226c-1.376 0-2.54.541-3.274 1.532-.77 1.008-.972 2.402-.559 3.824l1.449 4.988a.885.885 0 001.1.606.895.895 0 00.614-1.11l-1.449-4.989c-.265-.88-.165-1.678.266-2.256.395-.522 1.055-.807 1.853-.807h8.226c1.907 0 4.007 1.633 4.566 3.568l7.456 25.603a.88.88 0 00.853.651z"
          ></path>
          <path
            fill="#5C7479"
            d="M469.059 302.008h11.032a.898.898 0 00.889-.899.888.888 0 00-.889-.88h-11.032a.877.877 0 00-.89.88c0 .495.385.899.89.899zM472.057 312.315h11.032a.89.89 0 00.89-.889.898.898 0 00-.89-.899h-11.032a.886.886 0 00-.889.899c0 .495.385.889.889.889z"
          ></path>
          <path
            fill="#84CDCA"
            d="M527.364 84.019h79.966c4.75 0 9.729 3.86 11.114 8.629l14.691 50.51c1.385 4.76-1.348 8.63-6.107 8.63h-79.957c-4.75 0-9.739-3.87-11.124-8.63l-14.681-50.51c-1.394-4.769 1.338-8.63 6.098-8.63z"
          ></path>
          <path
            fill="#0C2933"
            d="M587.072 144.891h45.467c2.522 0 4.64-.981 5.979-2.751 1.403-1.889 1.752-4.365.972-7.015l-1.687-5.823a.876.876 0 00-1.101-.596.876.876 0 00-.605 1.1l1.687 5.814c.615 2.1.367 4.026-.697 5.447-.981 1.312-2.604 2.036-4.548 2.036h-45.467a.904.904 0 00-.889.899.89.89 0 00.889.889zM629.485 104.789a.9.9 0 00.862-1.146l-1.228-4.21a.885.885 0 00-1.101-.604.902.902 0 00-.614 1.11l1.229 4.199c.11.395.467.651.852.651zM530.454 98.416c.074 0 .165-.018.248-.037a.878.878 0 00.596-1.11l-3.677-12.663c-.606-2.091-.358-4.017.706-5.43.99-1.32 2.595-2.053 4.548-2.053h79.957c4.383 0 8.987 3.585 10.271 7.987l2.613 9.024a.902.902 0 001.11.605.898.898 0 00.605-1.11l-2.623-9.023c-1.485-5.108-6.859-9.262-11.976-9.262h-79.957c-2.522 0-4.649.972-5.979 2.76-1.403 1.87-1.751 4.356-.981 7.006l3.686 12.655c.11.394.459.651.853.651zM536.378 118.802c.083 0 .165-.018.248-.037a.919.919 0 00.614-1.109l-3.466-11.894a.89.89 0 00-1.101-.606.878.878 0 00-.605 1.101l3.457 11.894c.12.394.468.651.853.651z"
          ></path>
          <path
            fill="#fff"
            d="M431.698 309.298a.872.872 0 00.633-.256.898.898 0 000-1.266l-75.647-75.775a.88.88 0 00-1.265 0 .884.884 0 000 1.256l75.656 75.785a.865.865 0 00.623.256zM375.704 274.047a.9.9 0 00.633-.257.897.897 0 000-1.265l-43.77-43.844a.882.882 0 00-1.266 0 .887.887 0 000 1.257l43.77 43.852a.887.887 0 00.633.257z"
          ></path>
          <path
            fill="#0C2933"
            d="M701.784 183.196a.892.892 0 00.853-1.164c-7.969-23.962-33.921-43.45-57.846-43.45h-28.025c-23.22 0-48.411-18.946-56.15-42.23a.882.882 0 00-1.128-.559.889.889 0 00-.56 1.128c7.96 23.953 33.912 43.45 57.838 43.45h28.025c23.219 0 48.41 18.937 56.15 42.22a.894.894 0 00.843.605z"
          ></path>
          <path
            fill="#DAF0EF"
            d="M557.461 98.838c2.669 2.008 5.906 2.008 7.245 0 1.329-2 .247-5.255-2.412-7.254-2.669-2.008-5.915-2.008-7.245 0-1.329 2-.256 5.255 2.412 7.254z"
          ></path>
          <path
            fill="#0C2933"
            d="M558.177 90.97c1.155 0 2.448.477 3.576 1.33 2.256 1.687 3.265 4.456 2.21 6.043-.468.706-1.201.963-1.733 1.054-1.293.22-2.88-.256-4.228-1.274-2.256-1.688-3.264-4.457-2.21-6.044.468-.706 1.202-.962 1.734-1.045.21-.046.421-.064.651-.064zm3.393 10.262c.321 0 .651-.028.963-.083 1.256-.211 2.265-.844 2.916-1.816 1.614-2.43.458-6.153-2.623-8.464-1.761-1.33-3.806-1.907-5.603-1.605-1.256.211-2.265.844-2.916 1.825-1.614 2.421-.459 6.144 2.622 8.464 1.459 1.092 3.091 1.679 4.641 1.679z"
          ></path>
          <path
            fill="#DAF0EF"
            d="M699.602 185.755c2.668 2.008 5.906 2.008 7.245 0 1.329-2 .247-5.255-2.412-7.254-2.669-2.008-5.915-2.008-7.245 0-1.339 1.999-.257 5.254 2.412 7.254z"
          ></path>
          <path
            fill="#0C2933"
            d="M700.317 177.887c1.156 0 2.44.467 3.577 1.329 2.256 1.688 3.264 4.457 2.21 6.044-.468.706-1.202.962-1.733 1.045-1.293.229-2.88-.238-4.237-1.266-2.256-1.696-3.265-4.456-2.201-6.043.468-.706 1.192-.963 1.733-1.054a3.64 3.64 0 01.651-.055zm3.384 10.261c.33 0 .651-.018.972-.073 1.256-.22 2.265-.844 2.916-1.825 1.605-2.421.459-6.144-2.622-8.464-1.77-1.321-3.806-1.917-5.603-1.605-1.257.22-2.266.844-2.917 1.825-1.623 2.43-.467 6.144 2.623 8.464 1.458 1.091 3.09 1.678 4.631 1.678z"
          ></path>
          <path
            fill="#DAF0EF"
            d="M622.736 137.904l4.466-6.713c.779-1.174 2.677-1.174 4.246 0l8.922 6.713c1.559 1.174 2.192 3.072 1.413 4.255l-4.466 6.713c-.78 1.164-2.687 1.164-4.246 0l-8.923-6.713c-1.559-1.183-2.201-3.081-1.412-4.255z"
          ></path>
          <path
            fill="#0C2933"
            d="M629.045 131.209c.587 0 1.266.248 1.871.707l8.923 6.703c1.265.954 1.651 2.375 1.201 3.045l-4.457 6.712c-.165.239-.421.395-.77.459-.669.11-1.486-.138-2.201-.679l-8.932-6.703c-1.265-.963-1.65-2.375-1.201-3.054l4.466-6.704c.156-.238.413-.394.77-.458a1.88 1.88 0 01.33-.028zm6.438 19.432c.211 0 .422-.018.624-.055.834-.137 1.522-.577 1.953-1.219l4.466-6.713c1.054-1.586.348-3.989-1.614-5.456l-8.932-6.713c-1.119-.835-2.412-1.201-3.567-1.009-.826.147-1.523.578-1.954 1.229l-4.466 6.704c-1.063 1.586-.348 3.989 1.614 5.465l8.932 6.713c.917.688 1.963 1.054 2.944 1.054z"
          ></path>
          <path
            fill="#84CDCA"
            d="M651.999 201.996c5.19 0 7.996-4.219 6.263-9.418-1.724-5.209-7.336-9.428-12.527-9.428-5.199 0-8.015 4.219-6.281 9.428 1.733 5.199 7.345 9.418 12.545 9.418zm-9.29 16.057l-17.58-18.194c-.596-.77-.871-1.651-.761-2.412l4.677-56.719c.321-2.173 3.402-2.173 5.163 0l42.386 56.719c.614.761.926 1.642.843 2.412l-5.493 18.194c-.11 1.036-.917 1.706-2.054 1.706H645.9c-1.137 0-2.384-.67-3.191-1.706z"
          ></path>
          <path
            fill="#0C2933"
            d="M645.735 184.04c4.833 0 10.069 3.961 11.683 8.822.78 2.329.587 4.466-.532 6.024-1.027 1.431-2.769 2.22-4.888 2.22-4.842 0-10.087-3.962-11.701-8.813-.77-2.338-.578-4.475.541-6.034 1.027-1.431 2.76-2.219 4.897-2.219zm6.263 18.845c2.706 0 4.962-1.046 6.328-2.953 1.449-2.008 1.733-4.796.789-7.639-1.844-5.539-7.841-10.032-13.38-10.032-2.714 0-4.97 1.054-6.337 2.952-1.449 2.009-1.733 4.797-.788 7.649 1.834 5.529 7.84 10.023 13.388 10.023zm24.559-3.201l-5.466 18.103a.528.528 0 00-.027.165c-.092.825-.853.908-1.174.908H645.9c-.853 0-1.862-.541-2.485-1.358a.385.385 0 01-.064-.073l-17.543-18.148c-.422-.569-.633-1.201-.56-1.697l4.686-56.792c.055-.367.294-.633.707-.752.761-.202 1.989.156 2.879 1.247l42.358 56.692c.468.568.706 1.192.679 1.705zm-6.667 20.964c1.559 0 2.723-.972 2.925-2.412l5.466-18.112c.027-.055.027-.11.036-.165.11-1.008-.266-2.127-1.036-3.072l-42.358-56.7c-1.275-1.559-3.173-2.302-4.75-1.862-1.11.303-1.843 1.128-2.008 2.256l-4.687 56.802c-.137.935.202 2.054.954 3.017a.147.147 0 00.064.082l17.543 18.158c.982 1.238 2.449 2.008 3.861 2.008h23.99zM654.942 239.594h19.093c3.503 0 5.402-2.852 4.228-6.355l-.734-2.219c-1.165-3.503-4.952-6.355-8.455-6.355h-19.093c-3.503 0-5.401 2.852-4.236 6.355l.733 2.219c1.174 3.503 4.952 6.355 8.464 6.355z"
          ></path>
          <path
            fill="#0C2933"
            d="M643.204 176.438a.84.84 0 00.285-.055c.458-.165.715-.67.55-1.128-4.292-12.646-10.72-31.776-11.106-33.353a.89.89 0 00-.889-.825.871.871 0 00-.725.376c-.366.504-.366.504 5.393 17.671 2.815 8.363 5.639 16.699 5.639 16.699a.917.917 0 00.853.615z"
          ></path>
          <mask
            id="mask1_542_1671"
            style={{ maskType: "luminance" }}
            width="177"
            height="99"
            x="352"
            y="459"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M352.64 459.748h176.072v97.563H352.64v-97.563z"
            ></path>
          </mask>
          <g mask="url(#mask1_542_1671)">
            <mask
              id="mask2_542_1671"
              style={{ maskType: "luminance" }}
              width="177"
              height="100"
              x="352"
              y="459"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M528.712 459.748H352.64v98.6h176.072v-98.6z"
              ></path>
            </mask>
            <g mask="url(#mask2_542_1671)">
              <mask
                id="mask3_542_1671"
                style={{ maskType: "luminance" }}
                width="175"
                height="97"
                x="354"
                y="461"
                maskUnits="userSpaceOnUse"
              >
                <path
                  fill="#fff"
                  d="M354.52 461.627h173.531v95.684H354.52v-95.684z"
                ></path>
              </mask>
              <g mask="url(#mask3_542_1671)">
                <mask
                  id="mask4_542_1671"
                  style={{ maskType: "luminance" }}
                  width="177"
                  height="100"
                  x="352"
                  y="459"
                  maskUnits="userSpaceOnUse"
                >
                  <path
                    fill="url(#pattern0)"
                    d="M352.641 459.748H528.712V558.348H352.641z"
                  ></path>
                </mask>
                <g mask="url(#mask4_542_1671)">
                  <mask
                    id="mask5_542_1671"
                    style={{ maskType: "luminance" }}
                    width="177"
                    height="100"
                    x="352"
                    y="459"
                    maskUnits="userSpaceOnUse"
                  >
                    <path
                      fill="#fff"
                      d="M528.712 459.748H352.641v98.6h176.071v-98.6z"
                    ></path>
                  </mask>
                  <g mask="url(#mask5_542_1671)">
                    <mask
                      id="mask6_542_1671"
                      style={{ maskType: "luminance" }}
                      width="173"
                      height="73"
                      x="355"
                      y="462"
                      maskUnits="userSpaceOnUse"
                    >
                      <path
                        fill="#fff"
                        d="M355.025 462.168h172.963v72.107H355.025v-72.107z"
                      ></path>
                    </mask>
                    <g mask="url(#mask6_542_1671)">
                      <path
                        fill="#0C2933"
                        d="M514.938 465.974s-116.803-13.608-151.54 16.507c-34.729 30.116 54.838 159.647 54.838 159.647l28.319-21.046-26.86-91.548 107.972-30.72-12.729-32.84z"
                      ></path>
                    </g>
                  </g>
                </g>
                <mask
                  id="mask7_542_1671"
                  style={{ maskType: "luminance" }}
                  width="173"
                  height="73"
                  x="355"
                  y="462"
                  maskUnits="userSpaceOnUse"
                >
                  <mask
                    id="mask8_542_1671"
                    style={{ maskType: "luminance" }}
                    width="177"
                    height="100"
                    x="352"
                    y="459"
                    maskUnits="userSpaceOnUse"
                  >
                    <path
                      fill="#fff"
                      d="M528.712 459.748H352.641v98.6h176.071v-98.6z"
                    ></path>
                  </mask>
                  <g mask="url(#mask8_542_1671)">
                    <mask
                      id="mask9_542_1671"
                      style={{ maskType: "luminance" }}
                      width="175"
                      height="74"
                      x="354"
                      y="461"
                      maskUnits="userSpaceOnUse"
                    >
                      <path
                        fill="#fff"
                        d="M354.52 461.627h173.531v73.244H354.52v-73.244z"
                      ></path>
                    </mask>
                    <g mask="url(#mask9_542_1671)">
                      <mask
                        id="mask10_542_1671"
                        style={{ maskType: "luminance" }}
                        width="177"
                        height="76"
                        x="352"
                        y="459"
                        maskUnits="userSpaceOnUse"
                      >
                        <path
                          fill="#fff"
                          d="M528.712 459.748H352.641v75.123h176.071v-75.123z"
                        ></path>
                      </mask>
                      <g mask="url(#mask10_542_1671)">
                        <mask
                          id="mask11_542_1671"
                          style={{ maskType: "luminance" }}
                          width="173"
                          height="73"
                          x="355"
                          y="462"
                          maskUnits="userSpaceOnUse"
                        >
                          <path
                            fill="#fff"
                            d="M355.025 462.168h172.963v72.107H355.025v-72.107z"
                          ></path>
                        </mask>
                        <g mask="url(#mask11_542_1671)">
                          <mask
                            id="mask12_542_1671"
                            style={{ maskType: "luminance" }}
                            width="175"
                            height="74"
                            x="354"
                            y="461"
                            maskUnits="userSpaceOnUse"
                          >
                            <path
                              fill="url(#pattern1)"
                              d="M354.519 461.625H528.0550000000001V534.307H354.519z"
                            ></path>
                          </mask>
                          <g mask="url(#mask12_542_1671)">
                            <path
                              fill="url(#pattern2)"
                              d="M354.519 461.625H528.0550000000001V534.307H354.519z"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </mask>
                <g mask="url(#mask7_542_1671)">
                  <mask
                    id="mask13_542_1671"
                    style={{ maskType: "luminance" }}
                    width="177"
                    height="100"
                    x="352"
                    y="459"
                    maskUnits="userSpaceOnUse"
                  >
                    <path
                      fill="#fff"
                      d="M528.712 459.748H352.641v98.6h176.071v-98.6z"
                    ></path>
                  </mask>
                  <g mask="url(#mask13_542_1671)">
                    <mask
                      id="mask14_542_1671"
                      style={{ maskType: "luminance" }}
                      width="173"
                      height="73"
                      x="355"
                      y="462"
                      maskUnits="userSpaceOnUse"
                    >
                      <path
                        fill="#fff"
                        d="M355.025 462.168h172.963v72.107H355.025v-72.107z"
                      ></path>
                    </mask>
                    <g mask="url(#mask14_542_1671)">
                      <path
                        fill="#0C2933"
                        d="M514.938 465.974s-116.803-13.608-151.54 16.507c-34.729 30.116 54.838 159.647 54.838 159.647l28.319-21.046-26.86-91.548 107.972-30.72-12.729-32.84z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <mask
            id="mask15_542_1671"
            style={{ maskType: "luminance" }}
            width="255"
            height="106"
            x="352"
            y="452"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M352.64 452.705h253.543v104.606H352.64V452.705z"
            ></path>
          </mask>
          <g mask="url(#mask15_542_1671)">
            <mask
              id="mask16_542_1671"
              style={{ maskType: "luminance" }}
              width="255"
              height="107"
              x="352"
              y="452"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M606.183 452.705H352.64v105.642h253.543V452.705z"
              ></path>
            </mask>
            <g mask="url(#mask16_542_1671)">
              <mask
                id="mask17_542_1671"
                style={{ maskType: "luminance" }}
                width="251"
                height="105"
                x="354"
                y="453"
                maskUnits="userSpaceOnUse"
              >
                <path
                  fill="#fff"
                  d="M354.52 453.741h249.6v103.57h-249.6v-103.57z"
                ></path>
              </mask>
              <g mask="url(#mask17_542_1671)">
                <mask
                  id="mask18_542_1671"
                  style={{ maskType: "luminance" }}
                  width="255"
                  height="107"
                  x="352"
                  y="452"
                  maskUnits="userSpaceOnUse"
                >
                  <path
                    fill="url(#pattern3)"
                    d="M352.641 452.705H606.184V558.348H352.641z"
                  ></path>
                </mask>
                <g mask="url(#mask18_542_1671)">
                  <mask
                    id="mask19_542_1671"
                    style={{ maskType: "luminance" }}
                    width="255"
                    height="107"
                    x="352"
                    y="452"
                    maskUnits="userSpaceOnUse"
                  >
                    <path
                      fill="#fff"
                      d="M606.183 452.705H352.641v105.642h253.542V452.705z"
                    ></path>
                  </mask>
                  <g mask="url(#mask19_542_1671)">
                    <mask
                      id="mask20_542_1671"
                      style={{ maskType: "luminance" }}
                      width="249"
                      height="81"
                      x="355"
                      y="454"
                      maskUnits="userSpaceOnUse"
                    >
                      <path
                        fill="#fff"
                        d="M355.025 454.154h248.811v80.121H355.025v-80.121z"
                      ></path>
                    </mask>
                    <g mask="url(#mask20_542_1671)">
                      <path
                        fill="#0C2933"
                        d="M590.796 533.698c-16.966 5.383-58.893 4.264-101.324 3.686-12.059-.165-23.192 6.511-28.667 17.268-12.563 24.696-39.772 69.154-84.083 97.967l-21.165-11.417 54.013-121.81a44.926 44.926 0 0125.567-23.962l107.046-39.323s33.197-9.29 49.475 20.395c2.907 5.3 8.308-4.182 9.583.101 4.411 14.663 4.126 52.455-10.445 57.095z"
                      ></path>
                    </g>
                  </g>
                </g>
                <mask
                  id="mask21_542_1671"
                  style={{ maskType: "luminance" }}
                  width="249"
                  height="81"
                  x="355"
                  y="454"
                  maskUnits="userSpaceOnUse"
                >
                  <mask
                    id="mask22_542_1671"
                    style={{ maskType: "luminance" }}
                    width="255"
                    height="107"
                    x="352"
                    y="452"
                    maskUnits="userSpaceOnUse"
                  >
                    <path
                      fill="#fff"
                      d="M606.183 452.705H352.641v105.642h253.542V452.705z"
                    ></path>
                  </mask>
                  <g mask="url(#mask22_542_1671)">
                    <mask
                      id="mask23_542_1671"
                      style={{ maskType: "luminance" }}
                      width="251"
                      height="82"
                      x="354"
                      y="453"
                      maskUnits="userSpaceOnUse"
                    >
                      <path
                        fill="#fff"
                        d="M354.52 453.741h249.6v81.13h-249.6v-81.13z"
                      ></path>
                    </mask>
                    <g mask="url(#mask23_542_1671)">
                      <mask
                        id="mask24_542_1671"
                        style={{ maskType: "luminance" }}
                        width="255"
                        height="83"
                        x="352"
                        y="452"
                        maskUnits="userSpaceOnUse"
                      >
                        <path
                          fill="#fff"
                          d="M606.183 452.705H352.641v82.166h253.542v-82.166z"
                        ></path>
                      </mask>
                      <g mask="url(#mask24_542_1671)">
                        <mask
                          id="mask25_542_1671"
                          style={{ maskType: "luminance" }}
                          width="249"
                          height="81"
                          x="355"
                          y="454"
                          maskUnits="userSpaceOnUse"
                        >
                          <path
                            fill="#fff"
                            d="M355.025 454.154h248.811v80.121H355.025v-80.121z"
                          ></path>
                        </mask>
                        <g mask="url(#mask25_542_1671)">
                          <mask
                            id="mask26_542_1671"
                            style={{ maskType: "luminance" }}
                            width="251"
                            height="82"
                            x="354"
                            y="453"
                            maskUnits="userSpaceOnUse"
                          >
                            <path
                              fill="url(#pattern4)"
                              d="M354.519 453.738H604.1179999999999V534.308H354.519z"
                            ></path>
                          </mask>
                          <g mask="url(#mask26_542_1671)">
                            <path
                              fill="url(#pattern5)"
                              d="M354.519 453.738H604.1179999999999V534.308H354.519z"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </mask>
                <g mask="url(#mask21_542_1671)">
                  <mask
                    id="mask27_542_1671"
                    style={{ maskType: "luminance" }}
                    width="255"
                    height="107"
                    x="352"
                    y="452"
                    maskUnits="userSpaceOnUse"
                  >
                    <path
                      fill="#fff"
                      d="M606.183 452.705H352.641v105.642h253.542V452.705z"
                    ></path>
                  </mask>
                  <g mask="url(#mask27_542_1671)">
                    <mask
                      id="mask28_542_1671"
                      style={{ maskType: "luminance" }}
                      width="249"
                      height="81"
                      x="355"
                      y="454"
                      maskUnits="userSpaceOnUse"
                    >
                      <path
                        fill="#fff"
                        d="M355.025 454.154h248.811v80.121H355.025v-80.121z"
                      ></path>
                    </mask>
                    <g mask="url(#mask28_542_1671)">
                      <path
                        fill="#0C2933"
                        d="M590.796 533.698c-16.966 5.383-58.893 4.264-101.324 3.686-12.059-.165-23.192 6.511-28.667 17.268-12.563 24.696-39.772 69.154-84.083 97.967l-21.165-11.417 54.013-121.81a44.926 44.926 0 0125.567-23.962l107.046-39.323s33.197-9.29 49.475 20.395c2.907 5.3 8.308-4.182 9.583.101 4.411 14.663 4.126 52.455-10.445 57.095z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <mask
            id="mask29_542_1671"
            style={{ maskType: "luminance" }}
            width="106"
            height="85"
            x="383"
            y="473"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M383.16 473.833h105.643v83.478H383.16v-83.478z"
            ></path>
          </mask>
          <g mask="url(#mask29_542_1671)">
            <mask
              id="mask30_542_1671"
              style={{ maskType: "luminance" }}
              width="106"
              height="86"
              x="383"
              y="473"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#fff"
                d="M488.803 473.833H383.16v84.515h105.643v-84.515z"
              ></path>
            </mask>
            <g mask="url(#mask30_542_1671)">
              <mask
                id="mask31_542_1671"
                style={{ maskType: "luminance" }}
                width="106"
                height="83"
                x="383"
                y="475"
                maskUnits="userSpaceOnUse"
              >
                <path
                  fill="#fff"
                  d="M383.82 475.145h104.231v82.166H383.82v-82.166z"
                ></path>
              </mask>
              <g mask="url(#mask31_542_1671)">
                <mask
                  id="mask32_542_1671"
                  style={{ maskType: "luminance" }}
                  width="106"
                  height="88"
                  x="383"
                  y="473"
                  maskUnits="userSpaceOnUse"
                >
                  <path
                    fill="url(#pattern6)"
                    d="M383.16 473.833H488.803V560.695H383.16z"
                  ></path>
                </mask>
                <g mask="url(#mask32_542_1671)">
                  <mask
                    id="mask33_542_1671"
                    style={{ maskType: "luminance" }}
                    width="106"
                    height="86"
                    x="383"
                    y="473"
                    maskUnits="userSpaceOnUse"
                  >
                    <path
                      fill="#fff"
                      d="M488.803 473.833H383.16v84.515h105.643v-84.515z"
                    ></path>
                  </mask>
                  <g mask="url(#mask33_542_1671)">
                    <mask
                      id="mask34_542_1671"
                      style={{ maskType: "luminance" }}
                      width="104"
                      height="60"
                      x="384"
                      y="475"
                      maskUnits="userSpaceOnUse"
                    >
                      <path
                        fill="#fff"
                        d="M384.022 475.181h103.973v59.094H384.022v-59.094z"
                      ></path>
                    </mask>
                    <g mask="url(#mask34_542_1671)">
                      <path
                        fill="#fff"
                        d="M385.168 575.322a.9.9 0 00.816-.523l24.403-55.049c4.805-10.831 13.948-19.396 25.053-23.477l51.272-18.836a.878.878 0 00.522-1.137.889.889 0 00-1.137-.541l-51.271 18.836c-11.564 4.246-21.065 13.16-26.063 24.43l-24.411 55.041a.897.897 0 00.449 1.183.848.848 0 00.367.073z"
                      ></path>
                    </g>
                  </g>
                </g>
                <mask
                  id="mask35_542_1671"
                  style={{ maskType: "luminance" }}
                  width="104"
                  height="59"
                  x="384"
                  y="476"
                  maskUnits="userSpaceOnUse"
                >
                  <mask
                    id="mask36_542_1671"
                    style={{ maskType: "luminance" }}
                    width="106"
                    height="86"
                    x="383"
                    y="473"
                    maskUnits="userSpaceOnUse"
                  >
                    <path
                      fill="#fff"
                      d="M488.803 473.833H383.16v84.515h105.643v-84.515z"
                    ></path>
                  </mask>
                  <g mask="url(#mask36_542_1671)">
                    <mask
                      id="mask37_542_1671"
                      style={{ maskType: "luminance" }}
                      width="106"
                      height="60"
                      x="383"
                      y="475"
                      maskUnits="userSpaceOnUse"
                    >
                      <path
                        fill="#fff"
                        d="M383.82 475.145h104.231v59.727H383.82v-59.727z"
                      ></path>
                    </mask>
                    <g mask="url(#mask37_542_1671)">
                      <mask
                        id="mask38_542_1671"
                        style={{ maskType: "luminance" }}
                        width="106"
                        height="62"
                        x="383"
                        y="473"
                        maskUnits="userSpaceOnUse"
                      >
                        <path
                          fill="#fff"
                          d="M488.803 473.833H383.16v61.038h105.643v-61.038z"
                        ></path>
                      </mask>
                      <g mask="url(#mask38_542_1671)">
                        <mask
                          id="mask39_542_1671"
                          style={{ maskType: "luminance" }}
                          width="104"
                          height="59"
                          x="384"
                          y="476"
                          maskUnits="userSpaceOnUse"
                        >
                          <path
                            fill="#fff"
                            d="M384.022 476.181h103.974v58.094H384.022v-58.094z"
                          ></path>
                        </mask>
                        <g mask="url(#mask39_542_1671)">
                          <mask
                            id="mask40_542_1671"
                            style={{ maskType: "luminance" }}
                            width="106"
                            height="60"
                            x="383"
                            y="475"
                            maskUnits="userSpaceOnUse"
                          >
                            <path
                              fill="url(#pattern7)"
                              d="M383.817 475.148H488.051V534.308H383.817z"
                            ></path>
                          </mask>
                          <g mask="url(#mask40_542_1671)">
                            <path
                              fill="url(#pattern8)"
                              d="M383.817 475.148H488.051V534.308H383.817z"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </mask>
                <g mask="url(#mask35_542_1671)">
                  <mask
                    id="mask41_542_1671"
                    style={{ maskType: "luminance" }}
                    width="106"
                    height="86"
                    x="383"
                    y="473"
                    maskUnits="userSpaceOnUse"
                  >
                    <path
                      fill="#fff"
                      d="M488.803 473.833H383.16v84.515h105.643v-84.515z"
                    ></path>
                  </mask>
                  <g mask="url(#mask41_542_1671)">
                    <mask
                      id="mask42_542_1671"
                      style={{ maskType: "luminance" }}
                      width="104"
                      height="60"
                      x="384"
                      y="475"
                      maskUnits="userSpaceOnUse"
                    >
                      <path
                        fill="#fff"
                        d="M384.022 475.181h103.973v59.094H384.022v-59.094z"
                      ></path>
                    </mask>
                    <g mask="url(#mask42_542_1671)">
                      <path
                        fill="#fff"
                        d="M385.168 575.322a.9.9 0 00.816-.523l24.403-55.049c4.805-10.831 13.948-19.396 25.053-23.477l51.272-18.836a.878.878 0 00.522-1.137.889.889 0 00-1.137-.541l-51.271 18.836c-11.564 4.246-21.065 13.16-26.063 24.43l-24.411 55.041a.897.897 0 00.449 1.183.848.848 0 00.367.073z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <path
            fill="#84CDCA"
            d="M544.402 307.464c-24.283-.367-37.662.706-57.039 10.922-13.426 7.089-17.892 20.862-20.661 27.878 5.924 9.244 34.104 55.141 16.919 100.993-2.439 6.502-6.126 13.903-11.821 21.532 4.861 2.247 12.197 4.897 21.248 5.246 15.085.605 23.605-5.557 32.225-8.208 32.371-9.986 59.818 31.721 76.628 22.504 11.829-6.492 8.372-32.683 3.145-72.354-8.574-65.036-27.915-85.743-34.187-91.905-11.069-10.831-23.366-16.562-26.457-16.608z"
          ></path>
          <path
            fill="#fff"
            d="M352.962 304.016a4.333 4.333 0 00-1.743-1.843c-2.98-1.715-9.83-5.878-10.766-8.373-1.064-2.797 7.978-7.033 17.974-2.998 2.696 1.091 5.218 2.925 6.429 5.208.311.606.165.431.614 1.22 3.045 5.19 4.484 9.638 5.154 13.609.403 2.384 3.136 6.841 3.154 8.95.092 7.731-8.18 12.93-12.976-.706-.733-2.072-3.292-8.052-4.539-9.785-1.247-1.696-2.604-3.796-3.301-5.282z"
          ></path>
          <path
            fill="#0C2933"
            d="M350.1 290.031c2.394 0 5.127.44 8.006 1.605 2.77 1.119 4.943 2.87 5.961 4.796.11.211.156.321.192.413a7.1 7.1 0 00.45.834c2.641 4.521 4.292 8.868 5.034 13.307.211 1.21.89 2.751 1.605 4.383.725 1.623 1.532 3.476 1.541 4.429.046 3.393-1.733 6.374-4.044 6.786-2.632.468-5.31-2.448-7.199-7.804-.725-2.063-3.311-8.134-4.668-10.004-1.091-1.495-2.503-3.623-3.209-5.145a5.28 5.28 0 00-2.101-2.228c-5.832-3.357-9.812-6.392-10.38-7.915-.147-.357.302-.852.495-1.045 1.174-1.146 4.264-2.412 8.317-2.412zm18.231 38.387c.312 0 .596-.027.816-.064 3.21-.577 5.576-4.264 5.53-8.583-.018-1.321-.835-3.164-1.697-5.117-.651-1.468-1.311-2.981-1.485-3.971-.77-4.649-2.494-9.198-5.246-13.902-.247-.431-.302-.532-.366-.67-.046-.11-.101-.247-.239-.513-1.22-2.293-3.714-4.347-6.878-5.622-8.225-3.31-15.736-1.247-18.23 1.192-1.293 1.275-1.128 2.394-.917 2.953.953 2.531 6.593 6.199 11.16 8.831.614.358 1.091.853 1.376 1.44.651 1.385 1.916 3.411 3.383 5.438 1.193 1.651 3.724 7.547 4.43 9.546 2.742 7.786 6.336 9.042 8.363 9.042z"
          ></path>
          <path
            fill="#fff"
            d="M392.293 302.109l-3.09 13.407-6.401-1.862a2.33 2.33 0 01-.944-.541l-.487-.458a2.288 2.288 0 00-2.274-.523c-.44.128-.898.138-1.339.009l-47.594-13.838a4.534 4.534 0 01-1.119-.495l-10.729-6.511 11.967-.844a7.837 7.837 0 011.954.119l48.52 9.327c.413.064.798.266 1.11.541l.082.073a2.316 2.316 0 002.21.495l.385-.119c.358-.11.734-.128 1.11-.055l6.639 1.275z"
          ></path>
          <path
            fill="#0C2933"
            d="M391.22 302.806l-2.677 11.591-5.493-1.596a1.578 1.578 0 01-.587-.339l-.486-.458a3.23 3.23 0 00-3.146-.734c-.266.092-.55.092-.825.009l-47.585-13.838a3.4 3.4 0 01-.917-.394l-8.327-5.053 9.17-.651a6.71 6.71 0 011.715.11l48.521 9.317c.256.046.495.165.688.33l.082.083a3.226 3.226 0 003.063.678l.394-.119c.211-.064.45-.083.679-.037l5.731 1.101zm-2.017 13.609a.93.93 0 00.449-.12.928.928 0 00.422-.577l3.09-13.417a.88.88 0 00-.119-.678.936.936 0 00-.577-.395l-6.649-1.274a3.198 3.198 0 00-1.531.082l-.386.119c-.467.138-.99.028-1.366-.311l-.083-.065a3.16 3.16 0 00-1.531-.77l-48.52-9.317a8.687 8.687 0 00-2.183-.128l-11.967.843a.887.887 0 00-.395 1.651l10.73 6.52c.412.248.862.449 1.329.578l47.604 13.847a3.24 3.24 0 001.843-.027 1.447 1.447 0 011.403.33l.477.458c.376.349.825.606 1.311.743l6.401 1.871c.083.018.165.037.248.037z"
          ></path>
          <path
            fill="#0C2933"
            d="M329.669 298.899a.892.892 0 00.871-.734l1-5.355a.89.89 0 00-.716-1.046.888.888 0 00-1.036.716l-1 5.364a.895.895 0 00.716 1.037c.055.018.11.018.165.018z"
          ></path>
          <path
            fill="#D2DBAA"
            d="M400.198 318.423l-10.482-2.696a.837.837 0 01-.605-1l2.898-11.894a.834.834 0 011.018-.614l10.454 2.696c2.072.523 3.347 2.604 2.852 4.622l-1.504 6.19c-.486 2.017-2.568 3.237-4.631 2.696z"
          ></path>
          <path
            fill="#0C2933"
            d="M392.853 303.099l10.408 2.678c1.596.403 2.586 1.999 2.21 3.549l-1.513 6.19a2.747 2.747 0 01-1.275 1.733 2.952 2.952 0 01-2.265.312l-10.427-2.678 2.862-11.784zm8.336 16.351c.843 0 1.669-.229 2.411-.669a4.551 4.551 0 002.1-2.843l1.495-6.19c.615-2.485-.954-5.044-3.494-5.695l-10.463-2.696a1.727 1.727 0 00-1.312.183 1.782 1.782 0 00-.788 1.082l-2.889 11.894a1.73 1.73 0 001.247 2.082l10.482 2.696c.404.101.798.156 1.211.156zM386.873 312.967a.908.908 0 00.872-.67l1.99-7.804a.89.89 0 00-.633-1.091.909.909 0 00-1.091.651l-2 7.795a.899.899 0 00.862 1.119zM380.794 310.554c.403 0 .77-.275.871-.669l1.614-6.355a.882.882 0 00-.642-1.082.88.88 0 00-1.082.651l-1.623 6.346a.9.9 0 00.862 1.109zM376.126 309.298a.888.888 0 00.853-.651.898.898 0 00-.624-1.1l-14.122-3.889a.889.889 0 00-1.101.624.885.885 0 00.624 1.091l14.131 3.898a.698.698 0 00.239.027zM341.113 299.798a.917.917 0 00.862-.66c.119-.477-.156-.972-.632-1.092l-8.529-2.32a.892.892 0 00-1.091.624.9.9 0 00.623 1.1l8.529 2.311c.082.019.156.037.238.037z"
          ></path>
          <path
            fill="#fff"
            d="M403.243 309.491a.9.9 0 00.862-.66c.119-.477-.156-.972-.633-1.092l-8.574-2.274a.902.902 0 00-1.091.633.912.912 0 00.641 1.1l8.566 2.266c.082.018.156.027.229.027zM362.499 308.372c-.78-.908-1-1.715-1.412-2.705a10.283 10.283 0 00-3.238-4.191 44.47 44.47 0 00-2.861-2.027c-2.329-1.55-3.897-1.981-5.502-1.834-1.596.147-4.906-.137-3.466 2.421 0 0 .055.642 3.686 1.953 0 0 1.073.065 2.87 3.944 1.028 2.228 2.357 4.053 3.302 5.208.449.541 1.036.972 1.687 1.174 3.109.963 5.42-3.741 5.484-3.714l-.55-.229z"
          ></path>
          <path
            fill="#0C2933"
            d="M358.179 313.691c.358 0 .697-.22.835-.578a.888.888 0 00-.514-1.146c-.816-.312-2.237-1-2.714-1.504-1.009-1.036-2.183-2.889-3.146-4.952-1.595-3.467-2.788-4.292-3.448-4.448-1.641-.577-2.494-1.192-2.925-1.614a1.472 1.472 0 01-.431-.834c.183-.275 1.632-.495 2.852-.422 1.889.119 2.888.495 5.071 1.944a53.427 53.427 0 012.806 1.99 9.432 9.432 0 012.962 3.833c.523 1.266 1.192 1.853 2.953 2.623a.884.884 0 001.174-.468.895.895 0 00-.468-1.174c-1.485-.641-1.724-.944-2.026-1.669a11.134 11.134 0 00-3.504-4.557 53.823 53.823 0 00-2.907-2.064c-2.448-1.623-3.732-2.109-5.951-2.237-.532-.037-3.228-.138-4.292 1.009a1.764 1.764 0 00-.44 1.449c.101.678.44 1.338.954 1.852.568.55 1.65 1.357 3.649 2.045.028.018.065.027.092.037.129.073.963.623 2.274 3.448 1.028 2.237 2.33 4.282 3.476 5.456.972 1.009 3.255 1.889 3.356 1.926a.925.925 0 00.312.055z"
          ></path>
          <path
            fill="#84CDCA"
            d="M487.363 318.386s-30.666 24.265-63.634 29.52c-32.976 5.254-48.126-29.52-48.126-29.52l-16.314 2.577s10.326 76.82 53.289 79.15c42.963 2.311 87.513-24.348 87.513-24.348l-12.728-57.379z"
          ></path>
          <path
            fill="#fff"
            d="M535.397 374.61h147.121c5.355 0 10.344 4.347 11.123 9.72l15.975 109.211c.78 5.364-2.934 9.72-8.29 9.72h-147.12c-5.356 0-10.335-4.356-11.124-9.72L527.116 384.33c-.788-5.373 2.926-9.72 8.281-9.72z"
          ></path>
          <path
            fill="#5C7479"
            d="M682.517 375.499c4.925 0 9.519 4.017 10.253 8.969l15.956 109.201c.349 2.356-.238 4.557-1.659 6.199-1.394 1.614-3.43 2.494-5.741 2.494H554.205c-4.924 0-9.519-4.007-10.252-8.95l-15.957-109.21c-.348-2.357.248-4.558 1.66-6.199 1.394-1.614 3.43-2.504 5.75-2.504h147.111zM554.205 504.16h147.121c2.834 0 5.346-1.11 7.089-3.128 1.76-2.045 2.494-4.741 2.072-7.62l-15.966-109.21c-.843-5.787-6.226-10.482-12.004-10.482H535.406c-2.843 0-5.355 1.11-7.098 3.109-1.751 2.045-2.494 4.75-2.072 7.639l15.965 109.201c.844 5.777 6.227 10.491 12.004 10.491z"
          ></path>
          <path
            fill="#5C7479"
            d="M535.745 391.456h151.092a.89.89 0 00.889-.89.892.892 0 00-.889-.899H535.745a.9.9 0 00-.898.899c0 .486.403.89.898.89z"
          ></path>
          <path
            fill="#D2DBAA"
            d="M676.025 382.084c.193 1.384 1.477 2.503 2.852 2.503 1.366 0 2.32-1.119 2.118-2.503-.201-1.376-1.476-2.495-2.852-2.495-1.375 0-2.329 1.119-2.118 2.495zM666.066 382.084c.202 1.384 1.476 2.503 2.852 2.503 1.375 0 2.32-1.119 2.118-2.503-.192-1.376-1.476-2.495-2.852-2.495-1.366 0-2.32 1.119-2.118 2.495zM656.107 382.084c.202 1.384 1.476 2.503 2.852 2.503 1.375 0 2.329-1.119 2.127-2.503-.201-1.376-1.485-2.495-2.852-2.495-1.375 0-2.329 1.119-2.127 2.495z"
          ></path>
          <path
            fill="#5C7479"
            d="M534.379 382.973h14.022a.89.89 0 000-1.779h-14.022a.89.89 0 000 1.779zM554.71 382.973h67.797a.884.884 0 00.889-.889.884.884 0 00-.889-.89H554.71a.892.892 0 00-.898.89c0 .495.403.889.898.889zM637.775 382.973h12.362a.884.884 0 00.889-.889.884.884 0 00-.889-.89h-12.362a.884.884 0 00-.889.89c0 .495.394.889.889.889z"
          ></path>
          <path
            fill="#D2DBAA"
            d="M556.159 484.948H695.97l-12.407-84.872H543.76l12.399 84.872z"
          ></path>
          <path
            fill="#84CDCA"
            d="M680.289 482.976s20.505-44.119.147-90.988c-17.818-41.02-71.052-85.652-142.15-84.524l21.972 58.443s111.64 19.423 89.081 117.069h30.95z"
          ></path>
          <path
            fill="#fff"
            d="M654.291 482.976l-22.706 5.686s1.413 5.218 11.353 4.273c9.932-.953 7.575 8.052-3.31 9.95-10.885 1.898-4.732 5.218 10.408 4.264 15.141-.944 23.66-14.691 26.494-24.173h-22.239z"
          ></path>
          <path
            fill="#0C2933"
            d="M675.31 483.875c-3.357 10.05-11.821 21.541-25.329 22.385-9.39.596-13.81-.505-14.819-1.119.385-.266 1.513-.835 4.613-1.376 6.997-1.219 10.738-5.19 10.316-8.336-.128-.935-1.027-3.989-7.244-3.384-6.585.624-9.088-1.531-9.969-2.787l21.523-5.383h20.909zm-30.272 24.32c2.458 0 4.494-.12 5.053-.147 14.948-.945 24.036-13.948 27.291-24.815a.872.872 0 00-.137-.789.9.9 0 00-.716-.358h-22.238a.856.856 0 00-.211.028l-22.715 5.686a.892.892 0 00-.642 1.1c.065.238 1.734 5.933 12.298 4.915 3.081-.284 5.117.422 5.3 1.853.257 1.916-2.742 5.273-8.849 6.337-4.494.779-6.419 1.797-6.245 3.301.293 2.439 6.914 2.889 11.811 2.889z"
          ></path>
          <path
            fill="#fff"
            d="M499.055 310.903l-4.099-13.352c-.89.147-1.504.257-1.688.284-5.163 1.138-7.226-.146-8.647-4.282-.89-2.586-1.899-6.502-1.752-11.729a2.482 2.482 0 00-.468-1.559l-1.357-1.862a1.864 1.864 0 01.156-1.99l.385-.495c1.367-1.77 2.366-3.787 3.054-5.933.633-1.972 1.632-5.621 2.696-6.474 3.017-2.412 15.076-12.793 25.668-1.999 8.253 8.418 1.586 24.182-.945 29.427-.385.807 3.467 7.823 6.356 12.885a10.356 10.356 0 008.977 5.236l4.604.018s-3.907 14.343-25.173 15.792c-9.033.614-10.592-.881-10.592-.881l1.963-3.75a12.336 12.336 0 00.862-9.336z"
          ></path>
          <path
            fill="#0C2933"
            d="M530.729 309.958c-1.394 3.329-6.869 12.857-23.962 14.022-5.64.376-8.244-.083-9.308-.413l1.531-2.916a13.2 13.2 0 00.917-10.014l-4.09-13.343a.916.916 0 00-.999-.633c-1.311.22-1.623.275-1.742.303-5.044 1.109-6.429-.239-7.612-3.705-1.256-3.65-1.816-7.391-1.706-11.408a3.354 3.354 0 00-.642-2.109l-1.311-1.807a.972.972 0 01.11-.981l.376-.486c1.376-1.788 2.449-3.879 3.191-6.208.092-.285.184-.587.294-.917.486-1.596 1.412-4.576 2.118-5.145l.321-.257c3.586-2.888 14.48-11.665 24.146-1.806 7.63 7.776 1.77 22.467-1.101 28.419-.302.605-.66 1.366 6.374 13.709 1.999 3.494 5.74 5.677 9.748 5.686l3.347.009zm-27.896 15.948c1.164 0 2.503-.046 4.053-.147 21.642-1.477 25.934-16.305 25.971-16.452a.89.89 0 00-.156-.779.874.874 0 00-.706-.349l-4.604-.018a9.463 9.463 0 01-8.207-4.787c-5.429-9.51-6.163-11.72-6.245-12.197 3.053-6.391 9.042-21.77.697-30.29-7.172-7.308-16.095-6.749-26.53 1.669l-.321.248c-1.055.844-1.816 3.127-2.715 6.016-.101.321-.192.623-.284.899-.679 2.127-1.66 4.035-2.907 5.658l-.376.495a2.726 2.726 0 00-.248 2.953c.019.036.046.073.064.11l1.358 1.861c.211.275.321.642.302 1-.119 4.246.468 8.18 1.798 12.05 1.632 4.741 4.255 6.052 9.684 4.86.064-.009.266-.046.88-.156l3.861 12.61a11.36 11.36 0 01-.798 8.666l-1.963 3.75a.907.907 0 00.175 1.064c.256.248 1.66 1.266 7.217 1.266z"
          ></path>
          <path
            fill="#0C2933"
            d="M505.07 246.508c-3.906.055-8.051 1.624-10.014 1.376-3.163-.394-4.053-3.879-8.299-3.916-5.163-.064-6.713 2.495-7.951 5.842a9.106 9.106 0 00-.449 1.843c-1.155 8.95 3.65 12.068 5.667 12.976 0 0 12.298 3.136 14.554 4.329 2.109 1.109 4.961 4.227 4.878 9.693-.009.706.441 1.329 1.083 1.531.752.248 1.559-.137 1.861-.899.945-2.402 3.136-7.134 5.576-6.703 3.063.55 4.136 9.124-1.788 11.509-1.825.733-2.917 3.035-2.266 4.97.422 1.256 2 2.549 3.898 2.54 1.165 0 2.146-.706 2.98-1.55 3.421-3.493 12.224-17.001 11.372-27.089-1.018-12.141-15.902-16.534-21.102-16.452zM493.525 298.514c.083 0 .147-.009.23-.027.183-.055 4.548-1.266 7.097-4.705a.822.822 0 00-.174-1.155.82.82 0 00-1.155.165c-2.219 2.98-6.163 4.081-6.209 4.099a.814.814 0 00-.577 1.009.818.818 0 00.788.614z"
          ></path>
          <path
            fill="#fff"
            d="M342.269 470.954h165.892a2.149 2.149 0 002.146-2.146v-7.006H340.132v7.006c0 1.183.954 2.146 2.137 2.146z"
          ></path>
          <path
            fill="#5C7479"
            d="M509.417 462.691v3.522a3.84 3.84 0 01-3.833 3.842H344.855a3.84 3.84 0 01-3.834-3.842v-3.522h168.396zm-164.562 9.152h160.729c3.09 0 5.612-2.521 5.612-5.63v-4.411a.885.885 0 00-.889-.89H340.132a.886.886 0 00-.89.89v4.411c0 3.109 2.522 5.63 5.613 5.63z"
          ></path>
          <path
            fill="#fff"
            d="M340.132 461.802h97.371l-30.941-91.071c-1.852-2.843-5.071-4.585-8.546-4.585h-82.314c-3.732 0-6.135 3.631-4.915 6.841.486 1.274 1.009 2.531 1.431 3.824l27.914 84.991z"
          ></path>
          <path
            fill="#5C7479"
            d="M436.256 460.912h-95.482l-27.704-84.376c-.311-.954-.687-1.889-1.036-2.806-.137-.349-.284-.707-.413-1.055a4.103 4.103 0 01.477-3.769c.816-1.183 2.137-1.862 3.613-1.862h82.305c3.154 0 6.034 1.532 7.739 4.081l30.501 89.787zm-96.124 1.779h97.371a.912.912 0 00.725-.366.907.907 0 00.119-.807l-30.941-91.071a1.242 1.242 0 00-.092-.202c-2.026-3.118-5.502-4.989-9.298-4.989h-82.305c-2.072 0-3.924.963-5.08 2.641a5.893 5.893 0 00-.679 5.402c.138.366.276.724.422 1.082.34.889.697 1.806 1 2.714l27.915 84.982a.885.885 0 00.843.614z"
          ></path>
          <path
            fill="#D2DBAA"
            d="M376.264 412.823c1.164 3.86-.615 7.804-3.962 8.812-3.347 1.018-7.006-1.293-8.162-5.144-1.164-3.861.615-7.804 3.962-8.822 3.347-1.009 7.006 1.302 8.162 5.154z"
          ></path>
          <path
            fill="#fff"
            d="M422.932 454.676h-48.493v11.693h48.493v-11.693zM362.022 454.676h-15.856v11.693h15.856v-11.693z"
          ></path>
          <path
            fill="#5C7479"
            d="M264.183 534.523h397.545a.83.83 0 000-1.66H264.183a.837.837 0 00-.834.835c0 .449.375.825.834.825z"
          ></path>
          <path
            fill="#fff"
            d="M280.442 512.505s-10.087-3.155-26.594-35.251c-13.948-27.099-1.513-53.289.99-70.796.257-1.797 2.696-2.054 3.302-.348 4.181 11.765 10.903 33.169 9.812 46.338-1.559 18.992 12.49 60.057 12.49 60.057z"
          ></path>
          <path
            fill="#fff"
            d="M269.511 494.962l-3.595-28.447c-1.55-12.352.459-24.888 5.805-36.094a64.988 64.988 0 001.687-52.061l-5.768-14.425c-.724-1.825 1.596-3.319 2.907-1.861l8.107 8.923c18.368 20.257 24.576 48.924 16.241 75.077l-14.921 46.87-10.463 2.018z"
          ></path>
          <path
            fill="#D2DBAA"
            d="M267.778 482.142h.11a.89.89 0 00.77-1l-1.852-14.737a63.781 63.781 0 011.091-22.265c.101-.486-.193-.963-.669-1.064a.9.9 0 00-1.073.669c-1.715 7.529-2.082 15.223-1.128 22.89l1.87 14.727a.89.89 0 00.881.78z"
          ></path>
          <path
            fill="#fff"
            d="M274.142 495.898l12.453-31.877c3.549-9.069 11.005-15.993 20.239-18.799l8.501-2.577c2.055-.623 3.439 2.109 1.734 3.43l-5.218 4.071c-5.659 4.402-9.033 11.17-9.18 18.387a24.069 24.069 0 01-3.494 11.995l-8.446 13.875-16.589 1.495z"
          ></path>
          <path
            fill="#D2DBAA"
            d="M275.985 481.802h.083a.9.9 0 00.807-.972c-1.899-20.266 2.173-33.325 5.759-44.852 1.669-5.356 3.246-10.418 4.181-15.874 4.439-25.787-10.683-48.154-10.83-48.383-.293-.403-.834-.513-1.247-.229a.896.896 0 00-.229 1.238c.146.22 14.856 22.018 10.546 47.071-.917 5.338-2.476 10.345-4.127 15.645-3.632 11.683-7.758 24.925-5.832 45.549a.894.894 0 00.889.807zM281.267 472.421a.886.886 0 00.844-.605c1.596-4.824 5.19-9.849 7.951-13.224a.909.909 0 00-.129-1.256.899.899 0 00-1.256.137c-2.843 3.476-6.575 8.703-8.253 13.774-.156.477.1.981.568 1.137a.934.934 0 00.275.037zM259.387 468.194c.055 0 .11 0 .146-.009a.893.893 0 00.734-1.028c-3.375-20.064-2.21-50.959-2.192-51.271a.91.91 0 00-.862-.926.873.873 0 00-.917.862c-.018.302-1.201 31.399 2.219 51.629.065.44.45.743.872.743zM289.135 484.856c.486 0 .89-.394.899-.889.229-24.788 19.973-35.655 20.166-35.765a.883.883 0 00.367-1.201.892.892 0 00-1.202-.367c-.211.11-20.872 11.481-21.119 37.323a.896.896 0 00.88.899h.009zM286.439 437.995a.899.899 0 00.697-.339c5.457-7.024 7.804-16.222 7.905-16.617a.895.895 0 00-.651-1.082.895.895 0 00-1.082.651c-.018.092-2.357 9.226-7.575 15.948a.9.9 0 00.147 1.256.91.91 0 00.559.183z"
          ></path>
          <path
            fill="#5C7479"
            d="M258.47 532.927h37.241a4.98 4.98 0 004.979-4.988v-39.754h-47.2v39.754a4.986 4.986 0 004.98 4.988z"
          ></path>
          <path
            fill="#fff"
            d="M258.121 496.98h37.938c.495 0 .89-.404.89-.899a.891.891 0 00-.89-.89h-37.938a.89.89 0 00-.889.89c0 .495.394.899.889.899z"
          ></path>
          <path
            fill="#5C7479"
            d="M264.174 177.474c3.613 0 6.556-2.944 6.556-6.557 0-3.622-2.943-6.566-6.556-6.566a.897.897 0 00-.89.89c0 .495.404.898.89.898a4.772 4.772 0 110 9.547 4.771 4.771 0 01-4.769-4.769.886.886 0 00-.899-.889.884.884 0 00-.889.889c0 3.613 2.944 6.557 6.557 6.557zM557.846 413.07c.22 0 .44-.073.605-.238a.888.888 0 00.028-1.265l-2.797-2.935a.885.885 0 00-1.256-.028.89.89 0 00-.028 1.266l2.806 2.935a.903.903 0 00.642.265zM567.659 423.35c.211 0 .44-.082.614-.256a.894.894 0 00.028-1.257l-2.807-2.934a.885.885 0 00-1.256-.028.878.878 0 00-.027 1.257l2.797 2.943a.891.891 0 00.651.275zM564.898 413.235a.928.928 0 00.633-.256l2.843-2.807a.905.905 0 00.009-1.265.896.896 0 00-1.266-.009l-2.842 2.806c-.349.348-.358.917-.01 1.256a.884.884 0 00.633.275zM554.939 423.066a.948.948 0 00.633-.257l2.843-2.806a.897.897 0 00.009-1.265.886.886 0 00-1.257-.01l-2.852 2.807a.905.905 0 00-.009 1.265.9.9 0 00.633.266zM643.324 318.469c1.513.687 2.21 2.512 1.54 4.08a3.04 3.04 0 01-1.687 1.633 2.918 2.918 0 01-2.256-.055c-1.522-.688-2.21-2.522-1.541-4.081a2.981 2.981 0 011.688-1.632 2.875 2.875 0 012.256.055zm-1.211 7.703c.587 0 1.156-.119 1.715-.33a4.785 4.785 0 002.669-2.596c1.054-2.448-.046-5.328-2.449-6.41a4.705 4.705 0 00-3.631-.091 4.821 4.821 0 00-2.678 2.595c-1.045 2.448.046 5.328 2.448 6.41a4.74 4.74 0 001.926.422zM260.386 99.012a.91.91 0 00.615-.247.897.897 0 00.027-1.266l-2.806-2.934a.886.886 0 00-1.256-.028.897.897 0 00-.028 1.266l2.806 2.934a.892.892 0 00.642.275zM270.199 109.283a.88.88 0 00.614-.238.897.897 0 00.028-1.266l-2.806-2.934a.886.886 0 00-1.257-.028.897.897 0 00-.027 1.266l2.806 2.934a.902.902 0 00.642.266zM267.447 99.168c.221 0 .45-.083.624-.257l2.843-2.806c.348-.348.357-.917.018-1.256a.896.896 0 00-1.265-.019l-2.843 2.816a.885.885 0 00-.009 1.256.895.895 0 00.632.266zM257.488 108.999c.23 0 .441-.083.615-.257l2.861-2.806a.906.906 0 000-1.266.886.886 0 00-1.256-.009l-2.843 2.806a.888.888 0 00-.009 1.266.833.833 0 00.632.266z"
          ></path>
          <path
            fill="#D2DBAA"
            d="M666.387 358.259c.403 0 .77-.275.862-.688 8.152-34.398 7.712-59.497 5.915-74.482-1.963-16.314-5.814-24.366-5.979-24.696a.896.896 0 00-1.192-.421.907.907 0 00-.413 1.201c.165.321 15.379 32.793-.064 97.985a.89.89 0 00.66 1.073.805.805 0 00.211.028zM685.498 308.968c.477 0 .88-.385.889-.871 1.192-38.213-9.17-63.331-9.271-63.587a.886.886 0 00-1.165-.468.881.881 0 00-.476 1.164c.1.248 10.307 25.036 9.133 62.836a.905.905 0 00.862.926h.028z"
          ></path>
          <defs>
            <pattern
              id="pattern0"
              width="1"
              height="1"
              patternContentUnits="objectBoundingBox"
            >
              <use
                transform="scale(.01333 .0238)"
                xlinkHref="#image0_542_1671"
              ></use>
            </pattern>
            <pattern
              id="pattern1"
              width="1"
              height="1"
              patternContentUnits="objectBoundingBox"
            >
              <use
                transform="scale(.00325 .00775)"
                xlinkHref="#image1_542_1671"
              ></use>
            </pattern>
            <pattern
              id="pattern2"
              width="1"
              height="1"
              patternContentUnits="objectBoundingBox"
            >
              <use
                transform="scale(.00325 .00775)"
                xlinkHref="#image2_542_1671"
              ></use>
            </pattern>
            <pattern
              id="pattern3"
              width="1"
              height="1"
              patternContentUnits="objectBoundingBox"
            >
              <use
                transform="scale(.00926 .02222)"
                xlinkHref="#image3_542_1671"
              ></use>
            </pattern>
            <pattern
              id="pattern4"
              width="1"
              height="1"
              patternContentUnits="objectBoundingBox"
            >
              <use
                transform="scale(.00226 .007)"
                xlinkHref="#image4_542_1671"
              ></use>
            </pattern>
            <pattern
              id="pattern5"
              width="1"
              height="1"
              patternContentUnits="objectBoundingBox"
            >
              <use
                transform="scale(.00226 .007)"
                xlinkHref="#image5_542_1671"
              ></use>
            </pattern>
            <pattern
              id="pattern6"
              width="1"
              height="1"
              patternContentUnits="objectBoundingBox"
            >
              <use
                transform="scale(.02222 .02703)"
                xlinkHref="#image6_542_1671"
              ></use>
            </pattern>
            <pattern
              id="pattern7"
              width="1"
              height="1"
              patternContentUnits="objectBoundingBox"
            >
              <use
                transform="scale(.0054 .00952)"
                xlinkHref="#image7_542_1671"
              ></use>
            </pattern>
            <pattern
              id="pattern8"
              width="1"
              height="1"
              patternContentUnits="objectBoundingBox"
            >
              <use
                transform="scale(.0054 .00952)"
                xlinkHref="#image8_542_1671"
              ></use>
            </pattern>
            <image
              id="image0_542_1671"
              width="75"
              height="42"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEsAAAAqCAYAAAD75qYVAAAABmJLR0QA/wD/AP+gvaeTAAAAdUlEQVRoge3QwQkAIRDAwNX+az6uBfMSYaaCkDUz33Bk3w54iVmBWYFZgVmBWYFZgVmBWYFZgVmBWYFZgVmBWYFZgVmBWYFZgVmBWYFZgVmBWYFZgVmBWYFZgVmBWYFZgVmBWYFZgVmBWYFZgVmBWYFZgVnBD0oRAVL871USAAAAAElFTkSuQmCC"
            ></image>
            <image
              id="image1_542_1671"
              width="308"
              height="129"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATQAAACBCAAAAABrmhkxAAAAAmJLR0QA/4ePzL8AAAExSURBVHic7dBBDQJBEADBgaCHDwYQeILODVbw0I/dbFKloNMzsMJjrs/uhtPcr3l/d0ec5vfcXXAi0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtMC0wLTAtNY4w9n3APCTflf0gAAAABJRU5ErkJggg=="
            ></image>
            <image
              id="image2_542_1671"
              width="308"
              height="129"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATQAAACBCAIAAADBk9G6AAAABmJLR0QA/wD/AP+gvaeTAAAAiklEQVR4nO3BMQEAAADCoPVPbQwfoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADgbNIsAAEZgAkeAAAAAElFTkSuQmCC"
            ></image>
            <image
              id="image3_542_1671"
              width="108"
              height="45"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGwAAAAtCAYAAABLYo4EAAAABmJLR0QA/wD/AP+gvaeTAAAAhUlEQVR4nO3RwQkAIBDAsNP9ZxZn8CWFZIJC18ycIWP/DuCNYTGGxRgWY1iMYTGGxRgWY1iMYTGGxRgWY1iMYTGGxRgWY1iMYTGGxRgWY1iMYTGGxRgWY1iMYTGGxRgWY1iMYTGGxRgWY1iMYTGGxRgWY1iMYTGGxRgWY1iMYTGGxRgWcwEzOwFYKo5S0AAAAABJRU5ErkJggg=="
            ></image>
            <image
              id="image4_542_1671"
              width="443"
              height="143"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAbsAAACPCAAAAABGnirNAAAAAmJLR0QA/4ePzL8AAAFuSURBVHic7dFBDYAwAMDAQTCCMqQgClHYwMMeLE3uFDTpGFRt47xWNzDlOcZ5r45gyruvLmCad13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13edXnX5V2Xd13ewf8+uk8D1hWVY54AAAAASUVORK5CYII="
            ></image>
            <image
              id="image5_542_1671"
              width="443"
              height="143"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAbsAAACPCAIAAADsl+JGAAAABmJLR0QA/wD/AP+gvaeTAAAAz0lEQVR4nO3BgQAAAADDoPlTX+EAVQEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwG+cMAAGSgxgqAAAAAElFTkSuQmCC"
            ></image>
            <image
              id="image6_542_1671"
              width="45"
              height="37"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAlCAYAAADWSWD3AAAABmJLR0QA/wD/AP+gvaeTAAAARklEQVRYhe3OwQnAMAwAMbf7zxy6Qx8XDNIEembmzDLv7cAf0hXpinRFuiJdka5IV6Qr0hXpinRFuiJdka5IV6Qr0pWV6Q/UfwFIT+NMTgAAAABJRU5ErkJggg=="
            ></image>
            <image
              id="image7_542_1671"
              width="185"
              height="105"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALkAAABpCAAAAABD+N2vAAAAAmJLR0QA/4ePzL8AAACVSURBVHic7c5BEUBQAEBBZHASQDeFNBJHAhcdHNb8mbcJdt63aUTPNZ/H34lP7nX5u/BZc6+519xr7jX3mnvNveZec6+519xr7jX3mnvNveZec6+519xr7jX3mnvNveZec6+519xr7jX3mnvNveZec6+519xr7jX3mnvNveZec6+519xr7jX3mnvNveZec6+5N+58XC8T0ATPZU7csgAAAABJRU5ErkJggg=="
            ></image>
            <image
              id="image8_542_1671"
              width="185"
              height="105"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALkAAABpCAIAAADp8RUkAAAABmJLR0QA/wD/AP+gvaeTAAAAT0lEQVR4nO3BMQEAAADCoPVPbQlPoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA4GPkDAABDIwSNgAAAABJRU5ErkJggg=="
            ></image>
          </defs>
        </svg>
  )
}

export default DesignGraphic