import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import SwiperCore, { Navigation, Pagination } from "swiper";
import ArrowLeftCircle from "../../../svg/ArrowLeftCircle";
import ArrowRightCircle from "../../../svg/ArrowRightCircle";
import ReasonSingle from "./ReasonSingle";

const WhyChooseMbl = () => {
  const { t } = useTranslation();
  SwiperCore.use([Navigation, Pagination]);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  return (
    <Swiper
      breakpoints={{
        // when window width is >= 320px
        320: {
          width: 320,
          slidesPerView: 1,
        },
        // when window width is >= 768px
        768: {
          width: 768,
          slidesPerView: 1,
        },
        // when window width is >= 900px
        // 900: {
        //   width: 900,
        //   slidesPerView: 3,
        // },
      }}
      spaceBetween={40}
      slidesPerView={1}
      navigation={{
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current,
      }}
      onBeforeInit={(swiper) => {
        swiper.params.navigation.prevEl = navigationPrevRef.current;
        swiper.params.navigation.nextEl = navigationNextRef.current;
      }}
      // pagination={{ clickable: true }}
      // onSwiper={(swiper) => console.log(swiper)}
      // onSlideChange={() => console.log("slide change")}
      className="overflow-hidden"
    >
      <SwiperSlide>
        <ReasonSingle
          number="01"
          title={t("reason1_title")}
          traditional="reason1_traditional"
          aws="reason1_aws"
        />
      </SwiperSlide>
      <SwiperSlide>
        <ReasonSingle
          number="02"
          title={t("reason2_title")}
          traditional="reason2_traditional"
          aws="reason2_aws"
          reason2={true}
        />
      </SwiperSlide>
      <SwiperSlide>
        <ReasonSingle
          number="03"
          title={t("reason3_title")}
          traditional="reason3_traditional"
          aws="reason3_aws"
          reason3={true}
        />
      </SwiperSlide>
      <SwiperSlide>
        <ReasonSingle
          number="04"
          title={t("reason4_title")}
          traditional="reason4_traditional"
          aws="reason4_aws"
          reason4={true}
        />
      </SwiperSlide>
      <div className="flex gap-x-7 mt-3 lg:mt-7 w-max mx-auto">
        <div
          ref={navigationPrevRef}
          className="w-12 lg:w-16 cursor-pointer stroke-gray-400 hover:stroke-white ease-out duration-200"
        >
          <ArrowLeftCircle />
        </div>
        <div
          ref={navigationNextRef}
          className="w-12 lg:w-16 cursor-pointer stroke-gray-400 hover:stroke-white ease-out duration-200"
        >
          <ArrowRightCircle />
        </div>
      </div>
    </Swiper>
  );
};

export default WhyChooseMbl;
