import React from "react";
import CloseIcon from "../svg/CloseIcon";
import i18next from "i18next";

const LangSwitch = ({ langSwitchActivated, setLangSwitchActivated }) => {
  return (
    <div className="absolute top-[110%] right-0 bg-white pt-3 pb-1 px-1 min-w-[160px] rounded-sm text-theme-dark ring-gray-200 ring ring-offset-2 shadow-lg"> 
      <div
        className="w-6 stroke-theme-dark ml-auto cursor-pointer mr-1"
        onClick={() => setLangSwitchActivated(!langSwitchActivated)}
      >
        <CloseIcon />
      </div>
      <div className="flex flex-col gap-y-2">
        <button
          className="theme-smtext w-full hover:bg-[#E5E5E5] text-left px-5 ease-out duration-200"
          onClick={() => {
            i18next.changeLanguage("en");
            setLangSwitchActivated(false);
          }}
        >
          English
          <br />
          <span className="text-sm text-theme-gray">English</span>
        </button>
        <button
          className="theme-smtext w-full hover:bg-[#E5E5E5] text-left px-5 ease-out duration-200"
          onClick={() => {
            i18next.changeLanguage("ko");
            setLangSwitchActivated(false);
          }}
        >
          Korean
          <br />
          <span className="text-sm text-theme-gray">한국어</span>
        </button>
      </div>
    </div>
  );
};

export default LangSwitch;
