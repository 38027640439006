import React from "react";
import ContactFooter from "../components/contactpage/ContactFooter";
import ContactForm from "../components/contactpage/ContactForm";
import ContactGraphic from "../svg/illustrations/ContactGraphic";
import { useTranslation } from "react-i18next";

const ContactPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="main-container pt-32 pb-24 lg:pb-48 lg:pt-52 bg-theme-white text-theme-dark">
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="contact-graphic w-9/12 sm:w-2/5 mx-auto lg:w-4/12 flex flex-col items-center">
            <ContactGraphic />
            <div className="theme-smtext text-theme-gray pt-5">
            {t('made_in')}
            </div>
          </div>
          <div className="pt-12 lg:pt-0 lg:w-[53%] flex flex-col gap-y-5 lg:gap-y-7">
            <div className="hero-text flex flex-col gap-y-5 lg:gap-y-7">
              <div className="theme-h2">
              {t('contact_title')}
              </div>
              <div className="theme-p1">
              {t('contact_text')}
              </div>
            </div>
            <ContactForm />
          </div>
        </div>
      </div>
      <ContactFooter />
    </>
  );
};

export default ContactPage;
