import React from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MobileMenu = ({ setMobileMenuActivated, mobileMenuActivated }) => {
  const {t} = useTranslation();
  return (
      <div className="mbl-menu w-screen h-screen bg-theme-dark text-theme-white flex flex-col items-center justify-center gap-y-12 ">
        <HashLink
          smooth
          to="/#home"
          className="nav-link-mbl"
          onClick={() => setMobileMenuActivated(!mobileMenuActivated)}
        >
          { t('navLink_home') }
        </HashLink>
        <HashLink
          smooth
          to="/#services-section"
          className="nav-link-mbl"
          onClick={() => setMobileMenuActivated(!mobileMenuActivated)}
        >
          { t('navLink_services') }
        </HashLink>
        <HashLink
          smooth
          to="/#tech-section"
          className="nav-link-mbl"
          onClick={() => setMobileMenuActivated(!mobileMenuActivated)}
        >
          { t('navLink_tech') }
        </HashLink>
        <HashLink
          smooth
          to="/#values-section"
          className="nav-link-mbl"
          onClick={() => setMobileMenuActivated(!mobileMenuActivated)}
        >
          { t('navLink_values') }
        </HashLink>
        <Link
          to="/contact"
          className="nav-link-mbl"
          onClick={() => {setMobileMenuActivated(!mobileMenuActivated); window.scrollTo(0,0)}}
        >
          { t('navLink_contact') }
        </Link>
      </div>
  );
};

export default MobileMenu;
