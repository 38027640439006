import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReasonSingle from "./ReasonSingle";
import { motion, AnimatePresence } from "framer-motion";

const WhyChooseDesktop = () => {
  const { t } = useTranslation();

  const [reason1active, setReason1Active] = useState(true);
  const [reason2active, setReason2Active] = useState(false);
  const [reason3active, setReason3Active] = useState(false);
  const [reason4active, setReason4Active] = useState(false);

  return (
    <div className="desktop-slider flex justify-between items-center">
      <div className="reasons flex flex-col gap-y-16">
        <div
          onClick={() => {
            setReason1Active(true);
            setReason2Active(false);
            setReason3Active(false);
            setReason4Active(false);
          }}
          className="reason-1 cursor-pointer flex gap-x-7 items-center"
        >
          <div
            className={`theme-num ${
              reason1active ? "text-theme-teal" : "text-theme-gray opacity-70"
            } duration-300 ease-out`}
          >
            01
          </div>
          <div
            className={`theme-h2 ${
              reason1active ? "text-theme-white" : "text-theme-gray"
            } duration-300 ease-out`}
          >
            {t("reason1_title")}
          </div>
          <div
            className={`w-16 h-1 bg-theme-teal ${
              reason1active ? "flex" : "hidden"
            } duration-300 ease-out`}
          ></div>
        </div>
        <div
          onClick={() => {
            setReason1Active(false);
            setReason2Active(true);
            setReason3Active(false);
            setReason4Active(false);
          }}
          className="reason-2 cursor-pointer flex gap-x-7 items-center"
        >
          <div
            className={`theme-num ${
              reason2active ? "text-theme-teal" : "text-theme-gray opacity-70"
            } duration-300 ease-out`}
          >
            02
          </div>
          <div
            className={`theme-h2 ${
              reason2active ? "text-theme-white" : "text-theme-gray"
            } duration-300 ease-out`}
          >
            {t("reason2_title")}
          </div>
          <div
            className={`w-16 h-1 bg-theme-teal ${
              reason2active ? "flex" : "hidden"
            } duration-300 ease-out`}
          ></div>
        </div>
        <div
          onClick={() => {
            setReason1Active(false);
            setReason2Active(false);
            setReason3Active(true);
            setReason4Active(false);
          }}
          className="reason-3 cursor-pointer flex gap-x-7 items-center"
        >
          <div
            className={`theme-num ${
              reason3active ? "text-theme-teal" : "text-theme-gray opacity-70"
            } duration-300 ease-out`}
          >
            03
          </div>
          <div
            className={`theme-h2 ${
              reason3active ? "text-theme-white" : "text-theme-gray"
            } duration-300 ease-out`}
          >
            {t("reason3_title")}
          </div>
          <div
            className={`w-16 h-1 bg-theme-teal ${
              reason3active ? "flex" : "hidden"
            } duration-300 ease-out`}
          ></div>
        </div>
        <div
          onClick={() => {
            setReason1Active(false);
            setReason2Active(false);
            setReason3Active(false);
            setReason4Active(true);
          }}
          className="reason-4 cursor-pointer flex gap-x-7 items-center"
        >
          <div
            className={`theme-num ${
              reason4active ? "text-theme-teal" : "text-theme-gray opacity-70"
            } duration-300 ease-out`}
          >
            04
          </div>
          <div
            className={`theme-h2 ${
              reason4active ? "text-theme-white" : "text-theme-gray"
            } duration-300 ease-out`}
          >
            {t("reason4_title")}
          </div>
          <div
            className={`w-16 h-1 bg-theme-teal ${
              reason4active ? "flex" : "hidden"
            } duration-300 ease-out`}
          ></div>
        </div>
      </div>
      <div className="description w-1/2">
        {reason1active && (
          <motion.div
            initial={{ x: "50%" }}
            animate={{ x: 0 }}
            transition={{ duration: 0.4 }}
          >
            <ReasonSingle
              number="01"
              title={t("reason1_title")}
              traditional="reason1_traditional"
              aws="reason1_aws"
            />
          </motion.div>
        )}

        {reason2active && (
          <motion.div
            initial={{ x: "50%" }}
            animate={{ x: 0 }}
            transition={{ duration: 0.4 }}
          >
            <ReasonSingle
              number="02"
              title={t("reason2_title")}
              traditional="reason2_traditional"
              aws="reason2_aws"
              reason2={true}
            />
          </motion.div>
        )}

        {reason3active && (
          <motion.div
            initial={{ x: "50%" }}
            animate={{ x: 0 }}
            transition={{ duration: 0.4 }}
          >
            <ReasonSingle
              number="03"
              title={t("reason3_title")}
              traditional="reason3_traditional"
              aws="reason3_aws"
              reason3={true}
            />
          </motion.div>
        )}

        {reason4active && (
          <motion.div
            initial={{ x: "50%" }}
            animate={{ x: 0 }}
            transition={{ duration: 0.4 }}
          >
            <ReasonSingle
              number="04"
              title={t("reason4_title")}
              traditional="reason4_traditional"
              aws="reason4_aws"
              reason4={true}
            />
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default WhyChooseDesktop;
