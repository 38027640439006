import React, { useEffect, useState } from "react";
import HeroRow from "../components/homepage/HeroRow";
import HomeFooter from "../components/homepage/HomeFooter";
import MeetOurTeam from "../components/homepage/MeetOurTeam";
import OurValues from "../components/homepage/OurValues";
import ServicesSection from "../components/homepage/ServicesSection";
import TechnologiesSection from "../components/homepage/TechnologiesSection";
import WhyChooseDesktop from "../components/homepage/whychoose/WhyChooseDesktop";
import WhyChooseMbl from "../components/homepage/whychoose/WhyChooseMbl";
import { useTranslation } from "react-i18next";

const HomePage = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(true);
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.innerWidth < 1024 ? setIsMobile(true) : setIsMobile(false);
    }

    return () => {};
  }, []);
  return (
    <div className="bg-theme-dark text-theme-dark">
      <HeroRow />
      <ServicesSection />
      <TechnologiesSection />
      <OurValues />
      <div className="section-container main-container text-theme-white overflow-hidden">
        <div className="theme-h1 text-center mx-auto pb-5 lg:pb-36">
          {t("whychoose_title")}
        </div>

        {isMobile && <WhyChooseMbl />}
        {!isMobile && <WhyChooseDesktop />}
      </div>
      <MeetOurTeam />
      <div className="w-full bg-theme-white h-3 lg:h-5"></div>
      <HomeFooter />
    </div>
  );
};

export default HomePage;
