import React from "react";
import TechSlider from "./TechSlider";
import { useTranslation } from "react-i18next";

const TechnologiesSection = () => {
  const { t } = useTranslation();
  return (
    <div className="section-container" id="tech-section">
      <div className="main-container text-theme-white">
        <div className="theme-h1 text-center mx-auto pb-7">
          {t('tech_title')}
        </div>
        <div className="theme-p1 text-center">
        {t('tech_text')}
        </div>
        <TechSlider />
      </div>
    </div>
  );
};

export default TechnologiesSection;
