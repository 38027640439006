import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_ENDPOINT =
  "https://yew13sfstd.execute-api.ap-northeast-2.amazonaws.com/ideaMood/post_ideamood";

const ContactForm = ({ setPopupActive }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const notifySuccess = () => {
    toast.success(t("success_toast_message"), {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    setTimeout(()=>navigate("/"), 3000);
    setTimeout(()=>setPopupActive(false), 3000);
   
  };

  const notifyError = () => {
    toast.error(t("error_toast_message"), {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch(API_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, email, message }),
      });

      if (response.ok) {
        // alert(t("formSubmitSuccess"));
        notifySuccess();
       
        setName("");
        setEmail("");
        setMessage("");
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error(error);
      // alert(t("formSubmitError"));
      notifyError();
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <ToastContainer />
      <form
        className="w-full flex flex-col gap-y-3 lg:gap-y-7"
        method="post"
        onSubmit={handleSubmit}
      >
        <div className="form-content">
          <label htmlFor="name" className="theme-smtext pb-1 lg:pb-3">
            {t("formLabel_name")}
            <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            className="theme-input theme-smtext w-full"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            required
          />
        </div>
        <div className="form-content">
          <label htmlFor="email" className="theme-smtext pb-1 lg:pb-3">
            {t("formLabel_email")}
            <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="email"
            name="email"
            className="theme-input theme-smtext w-full"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            required
          />
        </div>
        <div className="form-content flex flex-col">
          <label htmlFor="message" className="theme-smtext pb-1 lg:pb-3">
            {t("formLabel_message")}
          </label>
          <textarea
            type="text"
            id="message"
            name="message"
            className="theme-input resize-none"
            rows="4"
            cols="50"
            maxLength="200"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
        </div>
        <button
          className="theme-btn bg-theme-teal text-theme-dark hover:bg-[#5BB1AD] cursor-pointer mx-auto"
          type="submit"
          disabled={isSubmitting}
        >
          {t("submit")}
        </button>
      </form>
    </>
  );
};

export default ContactForm;
