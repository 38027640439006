import React from 'react'

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      fill="none"
      viewBox="0 0 24 23"
      className='p-1'
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M2 2l20 18.648M22 2L2 20.648"
      ></path>
    </svg>
  )
}

export default CloseIcon