import React, { useState, useEffect, useRef } from "react";
import HeroBgImg from "../../svg/HeroBgImg";
import ContactPopUp from "./ContactPopUp";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";

let useClickOutside = (handler) => {
  let popupRef = useRef();
  useEffect(() => {
    let maybeHandler = (event) => {
      if (!popupRef.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return popupRef;
};

const HeroRow = () => {
  const {t} = useTranslation();
  const [popupActive, setPopupActive] = useState(false);
  let popupRef = useClickOutside(() => {
    setPopupActive(false);
  });
  return (
    <div
      className="hero-row relative h-[110vh] lg:h-screen bg-theme-dark overflow-hidden"
      id="home"
    >
      <div className="main-container relative z-[10] h-full flex items-end lg:items-center text-theme-white pb-[5vh] lg:pb-0 ">
        <div className="hero-text flex flex-col gap-y-5 lg:gap-y-7">
          <div className="theme-h1">
          { t('herorow_title') }
          </div>
          <div className="theme-p1">
          { t('herorow_text') }
          </div>
          <button
            className="theme-btn bg-theme-yellow text-theme-dark hover:bg-[#DAC04A] cursor-pointer"
            onClick={() => setPopupActive((popupActive) => !popupActive)}
          >
           { t('contact_cta_btn') }
          </button>
        </div>
      </div>
      <div className="absolute top-0 left-0 w-full h-full hidden lg:block"><img src="/img/bg.png" className="min-w-full"/></div>
      <div className="absolute top-0 left-0 w-full h-full lg:hidden"><img src="/img/bg_mbl.png" className="min-w-full"/></div>
      {/* <div className="bg-img absolute top-12 lg:top-0 left-8 sm:left-auto right-auto sm:right-0 w-[120%] sm:w-[80%] lg:w-1/2">
        <img src="/img/bg_transparent.png" alt="" className="min-w-full" />
        <HeroBgImg />
      </div> */}
      <AnimatePresence>
        {popupActive && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.45 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.6 }}
            className="overlay fixed top-0 left-0 bg-black w-full h-full z-[999999999]"
          ></motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {popupActive && (
          <motion.div
            ref={popupRef}
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.6 }}
            className="contact-popup fixed top-0 right-0 z-[9999999999]"
          >
            <ContactPopUp popupActive={popupActive} setPopupActive={setPopupActive}/>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default HeroRow;
