import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Logo from "../svg/Logo";
import LanguageIcon from "../svg/LanguageIcon";
import HamburgerIcon from "../svg/HamburgerIcon";
import CloseIcon from "../svg/CloseIcon";
import { HashLink } from "react-router-hash-link";
import { AnimatePresence, motion } from "framer-motion";
import MobileMenu from "./MobileMenu";
import LangSwitch from "./LangSwitch";
import { useTranslation } from "react-i18next";

let useClickOutside = (handler) => {
  let popupRef = useRef();
  useEffect(() => {
    let maybeHandler = (event) => {
      if (!popupRef.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return popupRef;
};

const Header = () => {
  const { t } = useTranslation();
  const [mobileMenuActivated, setMobileMenuActivated] = useState(false);
  const [langSwitchActivated, setLangSwitchActivated] = useState(false);
  let popupRef = useClickOutside(() => {
    setLangSwitchActivated(false);
  });

  return (
    <header className="header z-[999999] fixed top-0 left-0 w-full bg-theme-dark text-theme-white">
      <div className="main-container relative z-20 h-20 lg:h-24 flex items-center justify-between">
        <HashLink smooth to="/#home" className="logo w-28 lg:w-32">
          <Logo />
        </HashLink>
        <div className="nav-links flex gap-x-6 lg:gap-x-12 items-center">
          <HashLink smooth to="/#services-section" className="nav-link-lg">
            {t("navLink_services")}
          </HashLink>
          <HashLink smooth to="/#tech-section" className="nav-link-lg">
            {t("navLink_tech")}
          </HashLink>
          <HashLink smooth to="/#values-section" className="nav-link-lg">
            {t("navLink_values")}
          </HashLink>
          <Link
            to="/contact"
            className="nav-link-lg"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            {t("navLink_contact")}
          </Link>
          <div className="relative">
            <button
              className="language-icon w-7 lg:w-8 cursor-pointer p-[1px]"
              onClick={() => setLangSwitchActivated(!langSwitchActivated)}
            >
              <LanguageIcon />
            </button>
            <AnimatePresence>
              {langSwitchActivated && (
                <motion.div
                  ref={popupRef}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <LangSwitch
                    langSwitchActivated={langSwitchActivated}
                    setLangSwitchActivated={setLangSwitchActivated}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <button
            className="hamburger-icon lg:hidden w-7 cursor-pointer"
            onClick={() => setMobileMenuActivated(!mobileMenuActivated)}
          >
            {!mobileMenuActivated && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.4 }}
              >
                <HamburgerIcon />
              </motion.div>
            )}
            {mobileMenuActivated && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.4 }}
                className="stroke-white"
              >
                <CloseIcon />
              </motion.div>
            )}
          </button>
        </div>
      </div>
      <AnimatePresence>
        {mobileMenuActivated && (
          <motion.div
            initial={{ y: "-100%" }}
            animate={{ y: 0 }}
            exit={{ y: "-100%" }}
            transition={{ duration: 0.6 }}
            className="absolute z-10 top-0 left-0"
          >
            <MobileMenu
              mobileMenuActivated={mobileMenuActivated}
              setMobileMenuActivated={setMobileMenuActivated}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;
