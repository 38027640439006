import React from "react";
import CloseIcon from "../../svg/CloseIcon";
import ContactForm from "../contactpage/ContactForm";
import { useTranslation } from "react-i18next";

const ContactPopUp = ({ popupActive, setPopupActive }) => {
  const { t } = useTranslation();
  return (
    <div className="relative w-[395px] lg:w-[600px] max-w-[90vw] bg-white h-screen overflow-y-scroll flex items-center">
      <div className="main-container py-8 flex flex-col justify-center items-center gap-y-3 lg:gap-y-5">
        <div
          className="w-10 stroke-black cursor-pointer"
          onClick={() => setPopupActive((popupActive) => !popupActive)}
        >
          <CloseIcon />
        </div>

        <div className="hero-text flex flex-col gap-y-5 text-center">
          <div className="theme-h2">{t("contactPopup_title")}</div>
          <div className="theme-p1">{t("contactPopup_text")}</div>
        </div>

        <ContactForm setPopupActive={setPopupActive} />
      </div>
    </div>
  );
};

export default ContactPopUp;
