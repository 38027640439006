import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import SwiperCore, { Navigation, Pagination } from "swiper";
import techLogosData from "../../data/tech-logos.json";
import ArrowLeftCircle from "../../svg/ArrowLeftCircle"
import ArrowRightCircle from "../../svg/ArrowRightCircle"

const TechSlider = () => {
  SwiperCore.use([Navigation, Pagination]);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  return (
    <Swiper
      breakpoints={{
        // when window width is >= 320px
        320: {
          width: 320,
          slidesPerView: 2,
        },
        // when window width is >= 768px
        768: {
          width: 768,
          slidesPerView: 2,
        },
        // when window width is >= 900px
        900: {
          width: 900,
          slidesPerView: 3,
        },
      }}
      spaceBetween={0}
      slidesPerView={3}
      navigation={{
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current,
      }}
      onBeforeInit={(swiper) => {
        swiper.params.navigation.prevEl = navigationPrevRef.current;
        swiper.params.navigation.nextEl = navigationNextRef.current;
      }}
      // pagination={{ clickable: true }}
      // onSwiper={(swiper) => console.log(swiper)}
      // onSlideChange={() => console.log("slide change")}
      className="overflow-hidden mt-12"
    >
      {techLogosData.technologies.map((item, index) => {
        return (
          <SwiperSlide key={index}>
            <div className="relative min-w-full flex flex-col items-center justify-between h-48 lg:h-52">
              <img
                src={item.imgSrc}
                alt={item.imgAlt}
                className="w-2/5 max-w-[100px] my-auto"
              />
              <div className="theme-p2 text-gray-400">{item.name}</div>
              <div className="absolute top-0 right-0 bg-gray-400 w-[1px] h-full"></div>
            </div>
          </SwiperSlide>
        );
      })}
      <div className="flex gap-x-7 mt-10 w-max mx-auto">
        <div ref={navigationPrevRef} className="w-12 lg:w-16 cursor-pointer stroke-gray-400 hover:stroke-white ease-out duration-200">
          <ArrowLeftCircle />
        </div>
        <div ref={navigationNextRef} className="w-12 lg:w-16 cursor-pointer stroke-gray-400 hover:stroke-white ease-out duration-200">
          <ArrowRightCircle />
        </div>
      </div>
    </Swiper>
  );
};

export default TechSlider;
