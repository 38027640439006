import React from "react";
import teamData from "../../data/team-data.json";
import { useTranslation } from "react-i18next";

const MeetOurTeam = () => {
  const { t } = useTranslation();
  return (
    <div className="section-container bg-theme-white text-theme-dark">
      <div className="main-container">
        <div className="theme-h1 text-center mx-auto pb-12 lg:pb-36">
          {t("meetTeam_title")}
        </div>
        <div className="team flex flex-col gap-y-20 lg:gap-y-32">

          {/* member 3 */}
          <SingleMember
            imgSrc={"/img/team/3.png"}
            name={t("member3_name")}
            position={t("member3_position")}
            description={t("member3_description")}
          />
          {/* {teamData.members.map((member, index) => {
          return (
            <div
              key={index}
              className="single-member flex flex-col lg:flex-row lg:justify-between lg:items-center gap-y-10"
            >
              <div className="member-photo w-9/12 sm:w-2/5 mx-auto lg:w-4/12">
                <img
                  src={member.imgSrc}
                  alt={member.imgAlt}
                  className="min-w-full"
                />
              </div>
              <div className="team-text lg:w-[53%]">
                <div className="member-name theme-h2 pb-5 lg:pb-7">
                  {member.name}
                </div>
                <div className="member-position theme-h3 text-theme-teal pb-3">
                  {member.position}
                </div>
                <div className="separator bg-theme-teal w-full h-[1px] mb-5 lg:mb-7"></div>
                <div className="member-description theme-p2">
                  {member.description}
                </div>
              </div>
            </div>
          );
        })} */}
        </div>
      </div>
    </div>
  );
};

const SingleMember = ({ imgSrc, name, position, description }) => {
  return (
    <div className="single-member flex flex-col lg:flex-row lg:justify-between lg:items-center gap-y-10">
      <div className="member-photo w-9/12 sm:w-2/5 mx-auto lg:w-3/12">
        <img src={imgSrc} alt="A Person" className="min-w-full" />
      </div>
      <div className="team-text lg:w-[53%]">
        <div className="member-name theme-h3 pb-5 lg:pb-7">{name}</div>
        <div className="member-position theme-h4 text-theme-teal pb-3">
          {position}
        </div>
        <div className="separator bg-theme-teal w-full h-[1px] mb-5 lg:mb-7"></div>
        <div className="member-description theme-p2">{description}</div>
      </div>
    </div>
  );
};

export default MeetOurTeam;
