import React from "react";
import DesignGraphic from "../../svg/illustrations/DesignGraphic";
import DevelopmentGraphic from "../../svg/illustrations/DevelopmentGraphic";
import PlanningGraphic from "../../svg/illustrations/PlanningGraphic";
import ServerGraphic from "../../svg/illustrations/ServerGraphic";
import MarketingGraphic from "../../svg/illustrations/MarketingGraphic";
import { useTranslation } from "react-i18next";

const ServicesSection = () => {
  const { t } = useTranslation();
  return (
    <div className="section-container bg-theme-white" id="services-section">
      <div className="main-container">
        <div className="theme-h1 text-center mx-auto pb-12 lg:pb-36">
          {t('services_title')}
        </div>
        <div className="work-process flex flex-col gap-y-20 lg:gap-y-32">
          {/* Step 1  */}
          <SingleLeftGraphic
            illustration={<PlanningGraphic />}
            title= {t('step1_title')}
            subTitle1= {t('step1_subTitle1')}
            text1={t('step1_text1')}
            subTitle2={t('step1_subTitle2')}
            text2={t('step1_text2')}
          />

          {/* Step 2  */}
          <SingleRightGraphic
            illustration={<DesignGraphic />}
            title= {t('step2_title')}
            subTitle1= {t('step2_subTitle1')}
            text1={t('step2_text1')}
          />
          {/* Step 3  */}
          <SingleLeftGraphic
            illustration={<DevelopmentGraphic />}
            title= {t('step3_title')}
            subTitle1= {t('step3_subTitle1')}
            text1={t('step3_text1')}
          />
          {/* Step 4  */}
          <SingleRightGraphic
            illustration={<ServerGraphic />}
            title= {t('step4_title')}
            subTitle1= {t('step4_subTitle1')}
            text1={t('step4_text1')}
          />
          {/* Step 5  */}
          <SingleLeftGraphic
            illustration={<MarketingGraphic />}
            title= {t('step5_title')}
            subTitle1= {t('step5_subTitle1')}
            text1={t('step5_text1')}
          />
        </div>
      </div>
    </div>
  );
};

const SingleLeftGraphic = ({
  illustration: Illustration,
  title,
  title_para2,
  subTitle1,
  text1,
  subTitle2,
  text2,
}) => {
  return (
    <div className="flex flex-col lg:flex-row justify-between items-center gap-y-12 lg:gap-y-0">
      <div className="step-graphic w-full sm:w-9/12 lg:w-2/5">
        {Illustration}
      </div>
      <div className="step-text lg:w-[45%] flex flex-col gap-y-7">
        <div className="theme-h2">
          {title}
          <br />
          {title_para2}
        </div>
        <div className="step-text-child1 flex flex-col gap-y-4 lg:gap-y-3">
          <div className="theme-h4">{subTitle1}</div>
          <div className="theme-p2">
            {text1}
          </div>
        </div>
        <div className="step-text-child2 flex flex-col gap-y-4 lg:gap-y-3">
          <div className="theme-h4">{subTitle2}</div>
          <div className="theme-p2">{text2}</div>
        </div>
      </div>
    </div>
  );
};

const SingleRightGraphic = ({
  illustration: Illustration,
  title,
  subTitle1,
  text1,
  subTitle2,
  text2,
}) => {
  return (
    <div className="flex flex-col lg:flex-row justify-between items-center gap-y-12 lg:gap-y-0">
      <div className="step-graphic order- sm:w-9/12 lg:order-2 w-full lg:w-2/5">
        {Illustration}
      </div>
      <div className="step-text order-2 lg:order-1 lg:w-[45%] flex flex-col gap-y-7">
        <div className="theme-h2">
          {title}
        </div>
        <div className="step-text-child1 flex flex-col gap-y-4 lg:gap-y-3">
          <div className="theme-h4">{subTitle1}</div>
          <div className="theme-p2">
            {text1}
          </div>
        </div>
        <div className="step-text-child2 flex flex-col gap-y-4 lg:gap-y-3">
          <div className="theme-h4">{subTitle2}</div>
          <div className="theme-p2">{text2}</div>
        </div>
      </div>
    </div>
  );
};
export default ServicesSection;
