import React from "react";
import { Link } from "react-router-dom";
import { RiKakaoTalkFill } from "react-icons/ri";
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const HomeFooter = () => {
  const { t } = useTranslation();
  return (
    <div className="home-footer main-container text-theme-white">
      <div className="footer-text flex flex-col items-center justify-center gap-7 max-w-[335px] lg:max-w-[870px] py-28 mx-auto">
        <div className="theme-h2 text-center">{t('lets_talk')}</div>
        <div className="theme-p2 text-center">
        {t('footer_text')}
        </div>
        <Link
          to="/contact"
          className="theme-btn bg-theme-yellow text-theme-dark hover:bg-[#DAC04A] cursor-pointer"
          onClick={() => {
            window.scroll(0, 0);
          }}
        >
          {t('contact_cta_btn')}
        </Link>
      </div>
      <div className="flex flex-col lg:flex-row lg:justify-between items-center justify-center gap-y-5 pb-8">
        <div className="order-2 lg:order-1 text-gray-400 text-base lg:text-xl">
          © 2023, Idea Mood
        </div>
        <div className="order-1 lg:order-2 flex gap-x-5 lg:gap-x-7 text-gray-400 items-center text-xl lg:text-3xl">
          <a href="http://pf.kakao.com/_NDxjrxj" className="cursor-pointer">
            <RiKakaoTalkFill />
          </a>
          <a href="https://www.linkedin.com/company/ideamood/" className="cursor-pointer">
            <AiFillLinkedin />
          </a>
          <a href="" className="cursor-pointer">
            <AiFillFacebook />
          </a>
          <a href="https://instagram.com/ideamoodcokr?igshid=YmMyMTA2M2Y=" className="cursor-pointer">
            <AiFillInstagram />
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeFooter;
