import React from "react";
import ValuesGraphic from "../../svg/illustrations/ValuesGraphic";
import { useTranslation } from "react-i18next";

const OurValues = () => {
  const { t } = useTranslation();
  return (
    <div div className="section-container bg-theme-white" id="values-section">
      <div className="main-container">
        <div className="theme-h1 text-center mx-auto pb-12 lg:pb-36">
          {t("values_title")}
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="values-graphic w-9/12 sm:w-2/5 mx-auto lg:mx-0 lg:w-4/12">
            <ValuesGraphic />
          </div>
          <div className="values pt-12 lg:pt-0 lg:w-[53%] flex flex-col gap-y-10 lg:gap-y-24">
            {/* Value 1 */}
            <div className="value-1 w-full flex gap-x-7 lg:gap-14 justify-between">
              <div className="theme-values-num text-theme-gray">01</div>
              <div className="values-text flex flex-col gap-y-5 lg:gap-y-7">
                <div className="theme-h3">{t("value1_title")}</div>
                <div className="theme-p2">{t("value1_text")}</div>
              </div>
            </div>
            {/* Value 2 */}
            <div className="value-2 w-full flex gap-x-7 lg:gap-14 justify-between">
              <div className="theme-values-num text-theme-gray">02</div>
              <div className="values-text flex flex-col gap-y-5 lg:gap-y-7">
              <div className="theme-h3">{t("value2_title")}</div>
                <div className="theme-p2">{t("value2_text")}</div>
              </div>
            </div>
            {/* Value 3 */}
            <div className="value-3 w-full flex gap-x-7 lg:gap-14 justify-between">
              <div className="theme-values-num text-theme-gray">03</div>
              <div className="values-text flex flex-col gap-y-5 lg:gap-y-7">
              <div className="theme-h3">{t("value3_title")}</div>
                <div className="theme-p2">{t("value3_text")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurValues;
