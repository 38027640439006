import React from 'react'

const MarketingGraphic = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      fill="none"
      viewBox="0 0 669 592"
    >
      <path
        fill="#5C7479"
        d="M110.8 163.799c0 11.067-2.333 20.267-7 27.6-4.667 7.333-10.733 12.733-18.2 16.2-7.467 3.467-15.467 5.2-24 5.2s-16.6-1.733-24.2-5.2c-7.467-3.467-13.533-8.867-18.2-16.2-4.667-7.333-7-16.533-7-27.6v-50.6c0-10.933 2.333-20 7-27.2 4.667-7.2 10.733-12.467 18.2-15.8 7.6-3.467 15.667-5.2 24.2-5.2s16.533 1.733 24 5.2c7.467 3.333 13.533 8.6 18.2 15.8 4.667 7.2 7 16.267 7 27.2v50.6zm-75.2.8c0 9.2 2.533 15.8 7.6 19.8 5.067 3.867 11.2 5.8 18.4 5.8 7.2 0 13.267-1.933 18.2-5.8 5.067-4 7.6-10.6 7.6-19.8v-51c0-9.333-2.533-16-7.6-20-4.933-4-11-6-18.2-6-7.2 0-13.333 2-18.4 6-5.067 4-7.6 10.667-7.6 20v51zm195.647-1.6c0 10-2 18.733-6 26.2-4 7.467-9.8 13.267-17.4 17.4-7.467 4.133-16.267 6.2-26.4 6.2-18.267 0-34.133-6.2-47.6-18.6-.533-.667-.8-1.333-.8-2 0-.8.267-1.533.8-2.2l11-12c.667-.667 1.267-1 1.8-1 .8 0 1.6.4 2.4 1.2 10.4 8.133 21.267 12.2 32.6 12.2 8.4 0 14.8-2.4 19.2-7.2 4.533-4.933 6.8-11.667 6.8-20.2s-2.133-14.867-6.4-19c-4.133-4.133-10.6-6.2-19.4-6.2-5.733 0-10.6.733-14.6 2.2s-7.667 3.6-11 6.4c-.8.667-1.467 1.067-2 1.2-.533.133-1.2.067-2-.2l-13.6-4.6c-1.733-.4-2.6-1.533-2.6-3.4l2.6-69c0-2 1-3 3-3h78.8c2 0 3 1 3 3v16c0 2-1 3-3 3h-59.2l-1 33.2c6.8-4.133 14.933-6.2 24.4-6.2 14.4 0 25.733 4.133 34 12.4 8.4 8.133 12.6 19.533 12.6 34.2z"
      ></path>
      <path
        fill="#FDE268"
        d="M275.848 106.3c-35.385 13.636-63.742 43.396-74.619 64.504-42.868 83.202-32.727 258.884 42.901 352.055 17.696 21.802 107.463 86.67 234.87 64.283 112.63-19.795 178.981-128.26 188.618-220.428 7.981-76.36-19.342-176.753-87.538-213.28-42.885-22.963-29.897-6.904-127.407-26.799-84.399-17.218-133.543-37.009-176.825-20.335z"
        opacity="0.3"
      ></path>
      <path
        fill="#84CDCA"
        d="M245.037 218.305c-16.235 4.441-33-5.104-37.444-21.32-4.444-16.219 5.111-32.961 21.346-37.399 16.235-4.444 33 5.105 37.448 21.32 4.444 16.216-5.115 32.958-21.35 37.399zm52.587-39.813l-3.127-11.403-12.916.585a48.783 48.783 0 00-4.152-7.122l6.839-10.944-8.417-8.313-10.896 6.963a49.323 49.323 0 00-7.157-4.077l.445-12.91-11.452-3-5.944 11.449a49.648 49.648 0 00-8.248.062l-6.074-11.4-11.42 3.123.586 12.9a49.414 49.414 0 00-7.129 4.145l-10.954-6.829-8.326 8.407 6.969 10.886a49.685 49.685 0 00-4.08 7.145l-12.926-.446-3.003 11.443 11.465 5.934a49.522 49.522 0 00.059 8.238l-11.413 6.071 3.126 11.404 12.92-.586a49.07 49.07 0 004.148 7.119l-6.839 10.945 8.42 8.316 10.896-6.96a50.04 50.04 0 007.155 4.074l-.446 12.91 11.456 2.996 5.941-11.449c2.716.211 5.475.189 8.247-.059l6.078 11.401 11.419-3.124-.588-12.903a48.877 48.877 0 007.128-4.145l10.958 6.832 8.322-8.407-6.965-10.886a49.684 49.684 0 004.079-7.148l12.927.446 3.003-11.439-11.469-5.938a48.677 48.677 0 00-.059-8.238l11.414-6.068zM344.868 185.175c-12.262-.726-21.61-11.238-20.878-23.487.732-12.246 11.267-21.587 23.53-20.858 12.259.722 21.606 11.241 20.874 23.487-.729 12.246-11.263 21.583-23.526 20.858zm45.686-15.226l.514-8.616-9.068-2.612a35.582 35.582 0 00-1.197-5.892l7.294-5.964-3.868-7.714-9.162 2.264a36.04 36.04 0 00-3.992-4.486l3.338-8.824-7.21-4.747-6.796 6.524a36.144 36.144 0 00-5.717-1.884l-1.522-9.302-8.625-.507-2.623 9.054a35.91 35.91 0 00-5.898 1.201l-5.967-7.285-7.73 3.869 2.264 9.148a36.371 36.371 0 00-4.496 3.986l-8.83-3.328-4.757 7.203 6.533 6.787a35.74 35.74 0 00-1.893 5.706l-9.312 1.526-.514 8.612 9.064 2.616a35.51 35.51 0 001.201 5.892l-7.294 5.964 3.865 7.714 9.165-2.265a36.341 36.341 0 003.989 4.49l-3.335 8.817 7.213 4.75 6.793-6.526a35.331 35.331 0 005.713 1.884l1.526 9.301 8.625.511 2.619-9.058a35.763 35.763 0 005.902-1.197l5.97 7.285 7.724-3.866-2.264-9.155c1.61-1.2 3.11-2.534 4.496-3.985l8.833 3.328 4.757-7.203-6.533-6.784a35.445 35.445 0 001.89-5.707l9.315-1.525z"
      ></path>
      <path
        fill="#50A29E"
        d="M188.456 454.49l284.232-39.455c7.135-.908 12.484-6.969 12.484-14.15l3.898-197.878s-6.478-4.662-10.151-6.451a11.084 11.084 0 00-6.257-1.022l-283.389 36.072c-7.135.911-12.484 6.972-12.484 14.149v194.358a11.03 11.03 0 004.054 8.563c2.271 1.861 7.613 5.814 7.613 5.814z"
      ></path>
      <path
        fill="#84CDCA"
        d="M197.13 457.119l283.383-36.072c7.138-.907 12.483-6.969 12.483-14.152V212.547c0-6.68-5.872-11.843-12.513-10.997L197.1 237.618c-7.135.908-12.483 6.969-12.483 14.153v194.351c0 6.679 5.872 11.839 12.513 10.997z"
      ></path>
      <path
        fill="#000"
        d="M493.003 256.42a.89.89 0 01-.891-.891V212.54c0-2.925-1.26-5.71-3.456-7.645a10.234 10.234 0 00-8.052-2.47l-222.487 28.322a.888.888 0 01-.993-.771.882.882 0 01.768-.993l222.484-28.321a12.012 12.012 0 019.455 2.899 11.964 11.964 0 014.057 8.979v42.989a.887.887 0 01-.885.891z"
      ></path>
      <path
        fill="#fff"
        d="M203.149 449.109l270.593-34.445a10.373 10.373 0 009.074-10.288V216.903c0-4.46-3.92-7.909-8.355-7.343l-270.59 34.445c-5.186.657-9.077 5.065-9.077 10.287v187.47c0 4.464 3.923 7.909 8.355 7.347z"
      ></path>
      <path
        fill="#84CDCA"
        d="M362.453 571.577l291.452-37.513c2.284-.29 3.569-2.196 3.54-4.106-.023-1.311-.127-10.574-.127-10.574l-154.102-87.929c-7.724-4.402-17.716-6.104-26.536-4.975l-287.769 37.051a4.932 4.932 0 00-3.904 2.948l-.462 1.083s.247 7.721.147 9.523c-.079 1.412.556 2.867 2.036 3.713l150.237 85.736a40.965 40.965 0 0025.488 5.043z"
      ></path>
      <path
        fill="#fff"
        d="M362.453 561.276l290.414-37.444c3.852-.495 4.867-5.596 1.494-7.522l-149.434-85.184a40.987 40.987 0 00-25.488-5.046l-290.41 37.445c-3.856.494-4.867 5.596-1.494 7.519l149.434 85.186a40.99 40.99 0 0025.484 5.046z"
      ></path>
      <path
        fill="#000"
        d="M357.322 562.49c-7.255 0-14.481-1.887-20.796-5.486l-149.43-85.186c-1.877-1.067-2.818-3.127-2.398-5.241.42-2.115 2.076-3.661 4.22-3.934l290.407-37.444c8.954-1.146 18.203.686 26.044 5.153l10.314 5.882a.89.89 0 11-.882 1.546l-10.317-5.879c-7.505-4.279-16.358-6.032-24.934-4.936L189.142 464.41c-1.662.211-2.485 1.435-2.697 2.511-.215 1.077.078 2.522 1.533 3.352l149.429 85.183c7.506 4.278 16.362 6.032 24.935 4.935l214.276-27.628a.89.89 0 01.995.771.885.885 0 01-.768.995L362.57 562.158a41.748 41.748 0 01-5.248.332z"
      ></path>
      <path
        fill="#84CDCA"
        d="M305.598 513.99l251.821-32.708c3.481-.445 4.398-5.052 1.35-6.793l-59.207-33.41a37.013 37.013 0 00-23.022-4.558l-251.824 32.707c-3.485.443-4.399 5.053-1.35 6.79l59.21 33.414a37.012 37.012 0 0023.022 4.558z"
      ></path>
      <path
        fill="#000"
        d="M281.828 478.647a.889.889 0 01-.881-.774.889.889 0 01.761-.999l125.744-16.918a.89.89 0 11.238 1.763l-125.745 16.918a.66.66 0 01-.117.01zM363.328 483.762a.89.89 0 01-.878-.775.885.885 0 01.761-.998l65.295-8.87a.891.891 0 011.002.765.886.886 0 01-.762.999l-65.294 8.869a.852.852 0 01-.124.01z"
      ></path>
      <path
        fill="#84CDCA"
        d="M307.049 436.823c-8.66 1.341-16.973-5.807-18.522-15.932l-19.251-125.387 31.234-4.831 19.283 125.608c1.536 10.037-4.158 19.212-12.744 20.542z"
      ></path>
      <path
        fill="#000"
        d="M306.913 435.942c8.075-1.25 13.456-10.008 12.002-19.528l-19.15-124.726-29.474 4.561 19.114 124.509c.788 5.134 3.446 9.695 7.295 12.516 3.087 2.261 6.712 3.211 10.213 2.668zm-1.943 1.932c-3.253 0-6.497-1.09-9.324-3.162-4.227-3.101-7.142-8.088-8.001-13.684l-19.251-125.387a.893.893 0 01.745-1.015l31.234-4.835a.889.889 0 01.66.163c.192.14.319.348.355.582l19.287 125.611c1.604 10.486-4.448 20.156-13.489 21.555a14.47 14.47 0 01-2.216.172z"
      ></path>
      <path
        fill="#50A29E"
        d="M347.383 203.902l-87.008 19.479-37.451 16.01-17.849 13.75-.127.11c-3.332 2.278-5.228 9.966-4.519 18.89.699 8.827 3.823 16.056 7.421 17.904l-.137.013 18.77 10.388 39.257 9.615 89.845 4.997 90.015 57.44.676.306c-22.566-14.745-41.719-61.107-46.167-117.178-4.444-56.002 7.142-104.629 27.076-122.621l-.02-.019-78.773 70.018-1.009.898z"
      ></path>
      <path
        fill="#50A29E"
        d="M505.978 247.678c5.521 69.589-13.707 127.781-42.94 129.977-29.236 2.193-57.411-52.44-62.929-122.029-5.521-69.589 13.707-127.781 42.94-129.977 29.239-2.196 57.411 52.44 62.929 122.029z"
      ></path>
      <path
        fill="#000"
        d="M461.324 378.605c-13.932 0-27.87-12.21-39.436-34.64-11.94-23.155-19.989-54.496-22.667-88.27-4.054-51.123 5.15-97.96 23.451-119.316 6.136-7.161 12.969-11.068 20.312-11.618 15.015-1.136 30.046 11.921 42.318 36.748a.893.893 0 01-.407 1.194.895.895 0 01-1.194-.407c-11.748-23.78-26.535-36.807-40.584-35.765-6.852.517-13.277 4.219-19.091 11.006-18.008 21.015-27.047 67.341-23.029 118.018 5.473 68.987 33.267 123.372 61.973 121.209 20.656-1.549 37.041-32.447 41.742-78.715a.902.902 0 01.98-.801.896.896 0 01.797.98c-4.799 47.169-21.828 78.692-43.379 80.312-.599.046-1.191.065-1.786.065zM503.749 228.765a.89.89 0 01-.878-.761 283.995 283.995 0 00-1.926-11.521.888.888 0 111.747-.331c.719 3.8 1.369 7.701 1.942 11.595a.893.893 0 01-.751 1.012c-.046.006-.088.006-.134.006z"
      ></path>
      <path
        fill="#000"
        d="M473.118 159.967a.889.889 0 01-.736-.384c-8.869-12.757-18.584-19.118-28.107-18.408a.889.889 0 01-.953-.82.889.889 0 01.823-.95c10.18-.771 20.452 5.859 29.698 19.163a.89.89 0 01-.725 1.399zM454.111 363.001a.819.819 0 01-.261-.042c-23.714-7.271-44.104-52.576-48.48-107.723-3.992-50.329 6.793-95.881 26.226-110.775a.89.89 0 011.084 1.412c-18.984 14.549-29.483 59.464-25.537 109.223 2.102 26.461 8.108 51.776 16.915 71.284 8.648 19.147 19.414 31.533 30.316 34.878a.889.889 0 01.592 1.112.896.896 0 01-.855.631zM493.088 326.556a.89.89 0 01-.849-1.155c2.313-7.47 4.128-15.796 5.397-24.743a.895.895 0 011.005-.758.89.89 0 01.755 1.008c-1.278 9.042-3.117 17.459-5.456 25.02a.889.889 0 01-.852.628z"
      ></path>
      <path
        fill="#84CDCA"
        d="M449.354 252.005c1.282 16.112-3.169 29.584-9.939 30.095-6.771.508-13.294-12.142-14.57-28.257-1.281-16.111 3.176-29.587 9.94-30.094 6.774-.508 13.297 12.142 14.569 28.256z"
      ></path>
      <path
        fill="#000"
        d="M435.185 224.624c-.11 0-.221.003-.332.013-1.399.104-2.729.881-3.949 2.31-4.057 4.734-6.091 15.265-5.17 26.825 1.191 15.063 7.226 27.45 13.284 27.453.11 0 .218-.007.332-.016 1.399-.101 2.729-.879 3.953-2.307 4.057-4.737 6.084-15.269 5.17-26.829-1.198-15.06-7.233-27.449-13.288-27.449zm3.836 58.38c-7.19 0-13.759-12.584-15.067-29.092-.947-12.009 1.246-23.048 5.599-28.124 1.539-1.802 3.28-2.785 5.167-2.928 7.385-.563 14.192 12.217 15.526 29.074.953 12.008-1.24 23.047-5.59 28.123-1.545 1.802-3.286 2.788-5.173 2.931a7.13 7.13 0 01-.462.016z"
      ></path>
      <path
        fill="#000"
        d="M265.873 309.176l89.764 4.994c.15.006.296.058.426.133l80.573 51.418c-19.078-20.341-33.625-62.265-37.415-110.026-3.761-47.42 3.875-90.767 19.306-113.84l-70.552 62.711a.848.848 0 01-.397.201l-86.927 19.46-37.272 15.935-17.842 13.756c-.03.026-.056.049-.085.068-3.061 2.092-4.802 9.696-4.135 18.083.651 8.222 3.569 15.451 6.94 17.185.12.065.221.15.299.254l18.255 10.099 39.062 9.569zm180.406 64.52a.886.886 0 01-.374-.082l-.673-.306a1.763 1.763 0 01-.108-.058l-89.822-57.317-89.611-4.984a.955.955 0 01-.163-.023l-39.257-9.617a1.007 1.007 0 01-.218-.085l-18.772-10.388a.856.856 0 01-.283-.254c-3.797-2.372-6.764-9.634-7.457-18.373-.732-9.23 1.22-17.116 4.857-19.661l.091-.078 17.891-13.788a.85.85 0 01.195-.111l37.451-16.013c.049-.02.101-.036.153-.049l86.787-19.427 79.607-70.763a.898.898 0 011.49.722.9.9 0 01-.286.621c-9.624 8.694-17.582 25.241-22.404 46.594-5.01 22.195-6.523 48.236-4.376 75.299 2.148 27.098 7.76 52.625 16.229 73.828 8.146 20.393 18.639 35.554 29.538 42.676a.893.893 0 01.296 1.175.894.894 0 01-.781.462z"
      ></path>
      <path
        fill="#fff"
        d="M347.62 203.691l79.545-70.705.019.019c-19.934 17.992-31.519 66.619-27.075 122.621 4.447 56.071 23.601 102.433 46.167 117.178l-.677-.306-89.865-57.346-2.088-1.317c-10.421-6.725-19.284-27.684-21.239-53.045-2.008-26.032 5.056-48.175 14.224-56.247l.989-.852z"
      ></path>
      <path
        fill="#000"
        d="M418.527 141.855l-70.314 62.499c-.004 0-.007.004-.01.007l-.989.852c-8.466 7.454-15.968 28.921-13.919 55.508 1.858 24.118 10.428 45.65 20.836 52.365l2.082 1.315 80.423 51.32c-19.078-20.341-33.625-62.265-37.415-110.026-3.761-47.42 3.875-90.767 19.306-113.84zm27.753 231.841a.888.888 0 01-.375-.082l-.673-.306a1.479 1.479 0 01-.107-.058l-89.869-57.346-2.085-1.321c-11.023-7.109-19.723-28.699-21.652-53.725-2.19-28.436 5.993-49.473 14.524-56.985l.995-.856 79.535-70.698a.898.898 0 011.49.722.896.896 0 01-.286.621c-9.624 8.694-17.582 25.241-22.404 46.594-5.01 22.195-6.523 48.236-4.376 75.299 2.148 27.098 7.76 52.625 16.229 73.828 8.147 20.393 18.639 35.554 29.538 42.676a.893.893 0 01.296 1.175.894.894 0 01-.78.462zM225.468 300.772a.932.932 0 01-.43-.108l-17.757-9.825a.937.937 0 01-.287-.254c-3.793-2.372-6.76-9.637-7.453-18.376-.732-9.23 1.22-17.116 4.857-19.657l.091-.082 17.891-13.785a.89.89 0 111.087 1.409l-17.93 13.827c-.03.023-.056.046-.088.065-3.058 2.092-4.799 9.699-4.132 18.083.651 8.225 3.569 15.451 6.94 17.189.12.061.221.146.299.25l17.344 9.598a.888.888 0 01-.432 1.666z"
      ></path>
      <path
        fill="#84CDCA"
        d="M226.701 300.407c-5.87-2.977-10.704-15.08-11.846-29.47-1.211-15.252 2.739-28.842 8.661-31.773l-18.438 13.977-.13.11c-3.329 2.278-5.225 9.962-4.519 18.887.699 8.827 3.823 16.056 7.421 17.907l-.137.01 18.988 10.352z"
      ></path>
      <path
        fill="#000"
        d="M208.553 289.502l12.825 6.991c-3.784-5.547-6.562-14.803-7.411-25.487-.82-10.337.68-20.049 3.696-26.292l-12.126 9.207c-.03.02-.056.046-.088.065-3.058 2.096-4.799 9.696-4.132 18.083.651 8.225 3.569 15.451 6.94 17.185.117.062.218.15.296.248zm18.145 11.797a.888.888 0 01-.401-.098 12.505 12.505 0 01-.442-.237l-18.568-10.125a.935.935 0 01-.293-.257c-3.793-2.372-6.76-9.631-7.453-18.369-.732-9.234 1.22-17.12 4.857-19.665l.091-.078c.016-.013.033-.026.049-.042l16.163-12.253c.758-.774 1.565-1.389 2.418-1.809a.89.89 0 01.93 1.507l-2.251 1.705c-4.467 4.75-7.057 16.671-6.055 29.288 1.093 13.778 5.573 25.377 10.971 28.536l.413.225a.891.891 0 01-.429 1.672z"
      ></path>
      <path
        fill="#000"
        d="M264.308 309.706l1.688.348c-9.21-1.806-17.178-20.162-18.932-42.282-1.789-22.554 4.191-41.811 13.314-44.394l-1.253.533-35.509 15.2c-5.976 2.828-9.978 16.486-8.761 31.823 1.174 14.8 6.435 27.183 12.519 29.704l36.934 9.068z"
      ></path>
      <path
        fill="#000"
        d="M227.651 299.793l32.427 7.964c-6.965-6.448-12.451-21.619-13.902-39.914-1.376-17.38 1.897-33.13 7.835-40.773l-30.047 12.861c-5.755 2.724-9.379 16.32-8.221 30.935 1.135 14.322 6.139 26.464 11.908 28.927zm38.173 11.136a6.06 6.06 0 01-.13-.029l-1.601-.325-36.999-9.087-.065-.026c-6.481-2.691-11.852-15.214-13.063-30.456-1.233-15.559 2.75-29.614 9.27-32.698l34.828-14.911a9.164 9.164 0 012.07-.875l.592 1.672-1.93.823c-7.645 4.484-12.428 22.723-10.843 42.686 1.734 21.854 9.5 39.628 18.099 41.453l.12.023-.348 1.75zM434.788 223.748c-6.771.508-11.511 14.007-9.946 30.095 2.632 26.978 13.196 28.364 14.475 28.433.023 0 .023.035.003.039l-27.385 7.815c-2.44-10.929-4.226-22.697-5.202-35.002-.937-11.79-1.057-23.21-.462-33.973l28.517 2.593z"
      ></path>
      <path
        fill="#000"
        d="M407.11 222.128c-.546 10.672-.377 21.747.511 32.932.924 11.67 2.597 23.09 4.978 33.957l23.585-6.732c-3.992-1.968-10.268-8.273-12.227-28.354-1.145-11.729.983-22.713 5.42-27.986a10.415 10.415 0 011.702-1.637l-23.969-2.18zm4.158 69.118l-.205-.921c-2.501-11.222-4.262-23.041-5.218-35.125-.921-11.586-1.077-23.058-.462-34.093l.049-.921 29.434 2.677-.013 1.774c-1.451.11-2.837.933-4.112 2.456-4.08 4.844-6.094 15.562-5.014 26.666 2.56 26.281 12.513 27.57 13.639 27.628l.751.039.186 1.513-.739.235-28.296 8.072z"
      ></path>
      <path
        fill="#fff"
        d="M642.954 256.283a2.908 2.908 0 012.908 2.903v19.966a2.906 2.906 0 01-2.908 2.902H587.82a2.91 2.91 0 01-2.909-2.902v-19.966a2.913 2.913 0 012.909-2.903h55.134z"
      ></path>
      <path
        fill="#000"
        d="M642.954 282.943H587.82a3.8 3.8 0 01-3.797-3.794v-19.963a3.8 3.8 0 013.797-3.791h10.876a.893.893 0 010 1.783H587.82a2.014 2.014 0 00-2.017 2.008v19.963c0 1.11.904 2.011 2.017 2.011h55.134a2.016 2.016 0 002.017-2.011v-19.963a2.015 2.015 0 00-2.017-2.008h-36.928a.892.892 0 010-1.783h36.928a3.8 3.8 0 013.8 3.791v19.963a3.802 3.802 0 01-3.8 3.794z"
      ></path>
      <path
        fill="#000"
        d="M637.816 266.675h-43.815a.89.89 0 110-1.779h43.815c.495 0 .895.4.895.891a.89.89 0 01-.895.888zM637.816 272.522h-9.161a.89.89 0 010-1.78h9.161a.89.89 0 110 1.78zM622.801 272.522h-28.8a.89.89 0 110-1.78h28.8c.488 0 .892.397.892.888a.895.895 0 01-.892.892z"
      ></path>
      <path
        fill="#84CDCA"
        d="M531.41 251.114c-9.988-9.845-10.134-25.957-.325-35.984 9.806-10.031 25.856-10.177 35.844-.332 9.991 9.845 10.138 25.957.328 35.984-9.806 10.03-25.855 10.18-35.847.332z"
      ></path>
      <path
        fill="#fff"
        d="M566.35 231.593l-17.221 17.604a2.35 2.35 0 01-3.335.033l-12.109-11.937a2.368 2.368 0 01-.03-3.345l17.221-17.608a2.348 2.348 0 013.328-.029l12.113 11.937c.927.914.944 2.414.033 3.345z"
      ></path>
      <path
        fill="#000"
        d="M552.552 216.526h-.013a1.437 1.437 0 00-1.031.442l-17.221 17.605a1.485 1.485 0 00.019 2.089l12.113 11.937c.28.276.638.436 1.038.423.39-.007.758-.163 1.031-.44l17.218-17.607a1.483 1.483 0 00-.016-2.089l-12.113-11.937a1.455 1.455 0 00-1.025-.423zm-5.108 34.275a3.225 3.225 0 01-2.274-.933l-12.113-11.937a3.27 3.27 0 01-.042-4.604l17.217-17.605a3.243 3.243 0 014.594-.042l12.116 11.937a3.267 3.267 0 01.039 4.6l-17.221 17.608a3.213 3.213 0 01-2.287.976h-.029z"
      ></path>
      <path
        fill="#000"
        d="M539.772 236.662a.892.892 0 01-.192-1.76l10.994-2.398a.88.88 0 011.057.676.885.885 0 01-.676 1.061l-10.991 2.401a.94.94 0 01-.192.02z"
      ></path>
      <path
        fill="#000"
        d="M550.814 234.215a.887.887 0 01-.875-1.064l2.186-11.071a.896.896 0 011.048-.706.894.894 0 01.7 1.047l-2.187 11.075a.895.895 0 01-.872.719z"
      ></path>
      <path
        fill="#fff"
        d="M465.781 197.431c0-12.712 10.326-23.015 23.067-23.015 12.731 0 23.054 10.303 23.054 23.015 0 12.711-10.323 23.015-23.054 23.015-12.741 0-23.067-10.304-23.067-23.015z"
      ></path>
      <path
        fill="#000"
        d="M488.845 175.304c-12.224 0-22.169 9.926-22.169 22.127 0 12.197 9.945 22.127 22.169 22.127 12.223 0 22.169-9.93 22.169-22.127 0-12.201-9.946-22.127-22.169-22.127zm0 46.034c-13.209 0-23.949-10.724-23.949-23.907s10.74-23.907 23.949-23.907c13.203 0 23.949 10.724 23.949 23.907s-10.746 23.907-23.949 23.907z"
      ></path>
      <path
        fill="#5479F7"
        d="M495.072 199.728a3.982 3.982 0 00-3.172 1.575l-7.786-3.026c.01-.101.029-.202.029-.306 0-.195-.029-.381-.058-.569l7.984-3.683a3.991 3.991 0 003.003 1.382c2.219.007 4.024-1.805 4.024-4.037.004-2.229-1.802-4.048-4.024-4.054-2.219-.003-4.028 1.809-4.028 4.038 0 .195.033.383.059.569l-7.981 3.686a4 4 0 00-3.003-1.386 4.033 4.033 0 00-4.025 4.038c-.003 2.232 1.803 4.054 4.025 4.057 1.291 0 2.433-.625 3.169-1.581l7.789 3.025c-.01.105-.036.202-.036.309 0 2.229 1.809 4.048 4.028 4.054 2.222.004 4.027-1.812 4.027-4.041 0-2.231-1.805-4.047-4.024-4.05z"
      ></path>
      <path
        fill="#fff"
        d="M513.754 304.002c0-10.661 8.664-19.306 19.345-19.306 10.678 0 19.342 8.645 19.342 19.306 0 10.665-8.664 19.307-19.342 19.307-10.681 0-19.345-8.642-19.345-19.307z"
      ></path>
      <path
        fill="#000"
        d="M533.099 285.588c-10.177 0-18.454 8.26-18.454 18.415 0 10.157 8.277 18.414 18.454 18.414 10.174 0 18.451-8.257 18.451-18.414 0-10.155-8.277-18.415-18.451-18.415zm0 38.612c-11.163 0-20.234-9.061-20.234-20.197 0-11.137 9.071-20.195 20.234-20.195 11.156 0 20.23 9.058 20.23 20.195 0 11.136-9.074 20.197-20.23 20.197z"
      ></path>
      <path
        fill="#84CDCA"
        d="M533.206 313.714a.625.625 0 01-.631.013c-1.701-.95-9.611-5.706-9.302-11.927.326-6.572 7.477-5.437 9.237-2.199a.375.375 0 00.342.192.36.36 0 00.315-.222c.866-2.082 3.664-3.585 6.296-2.599 3.139 1.174 6.331 8.957-6.257 16.742z"
      ></path>
      <path
        fill="#fff"
        d="M588.708 135.494c-4.584-12.122 1.532-25.716 13.661-30.362 12.129-4.646 25.677 1.416 30.261 13.538 4.581 12.123-1.536 25.716-13.665 30.362-12.129 4.643-25.673-1.415-30.257-13.538z"
      ></path>
      <path
        fill="#000"
        d="M610.786 104.456c-2.73 0-5.469.501-8.098 1.509-11.654 4.464-17.556 17.572-13.151 29.217 2.131 5.635 6.322 10.086 11.811 12.548 5.501 2.47 11.644 2.636 17.295.469 11.654-4.464 17.55-17.569 13.151-29.21-2.131-5.635-6.325-10.089-11.81-12.552a22.395 22.395 0 00-9.198-1.981zm-.241 47.036a24.2 24.2 0 01-9.929-2.138c-5.919-2.655-10.444-7.467-12.741-13.544-4.75-12.559 1.61-26.692 14.175-31.507 6.097-2.333 12.725-2.151 18.662.507 5.922 2.658 10.451 7.467 12.744 13.548 4.75 12.555-1.61 26.692-14.175 31.507a24.436 24.436 0 01-8.736 1.627z"
      ></path>
      <path
        fill="#84CDCA"
        d="M622.71 133.025l-4.021-2.883c-.54-.361-1.617-.442-2.792.361-1.106.755-2.886 1.943-6.917-2.03-4.031-3.972-3.035-5.892-2.144-6.888.137-.153 1.103-1.6.316-2.804l-2.291-3.091c-1.893-2.375-3.839-.12-3.953.01-3.201 3.566-1.255 10.505 4.617 16.287 5.876 5.785 12.838 7.62 16.349 4.356.12-.114 2.219-2.238.836-3.318zM613.34 214.964c-8.43-8.306-8.553-21.902-.276-30.362 8.276-8.462 21.814-8.586 30.244-.276 8.427 8.306 8.554 21.899.277 30.362-8.271 8.459-21.812 8.582-30.245.276z"
      ></path>
      <path
        fill="#fff"
        d="M624.376 206.973l-1.344-13.147a.915.915 0 011.227-.947l13.84 5.144a.907.907 0 01.173 1.617l-12.5 8.004a.908.908 0 01-1.396-.671z"
      ></path>
      <path
        fill="#84CDCA"
        d="M527.529 150.063c.004-11.836 9.624-21.483 21.483-21.548 11.859-.062 21.467 9.484 21.46 21.32-.003 11.833-9.62 21.48-21.479 21.542-11.859.065-21.467-9.481-21.464-21.314z"
      ></path>
      <path
        fill="#fff"
        d="M555.571 139.58l2.827-.003c.352 0 .641.287.641.638l.016 19.446a.64.64 0 01-.637.638l-2.831.006a.646.646 0 01-.641-.641l-.016-19.446c0-.351.289-.638.641-.638zM547.584 145.612l2.827-.003a.64.64 0 01.638.638l.013 13.424a.642.642 0 01-.638.641h-2.827a.638.638 0 01-.641-.638l-.013-13.424a.64.64 0 01.641-.638zM539.599 153.307l2.828-.003c.354 0 .644.286.644.637l.003 5.733a.643.643 0 01-.637.641h-2.831a.637.637 0 01-.638-.638l-.006-5.729c0-.355.286-.641.637-.641z"
      ></path>
      <path
        fill="#000"
        d="M618.455 384.719a7.258 7.258 0 001.402 2.358c1.614 1.796 4.054 2.567 6.37 3.257 1.91.569 3.852 1.145 5.847 1.165 3.946.029 7.636-2.125 11.576-2.31 2.671-.13 5.29.647 7.928 1.093 4.972.846 8.954-1.161 12.653-4.415 6.553-5.772 6.205-21.984-.136-32.255-6.706-10.86-17.133-10.284-18.578-19.599-.833-5.359 2.697-7.591.914-13.301-.937-2.986-3.63-5.557-9.022-10.694-7.391-7.047-10.482-8.114-11.556-8.452-1.819-.57-5.502-1.66-9.406-.062-1.171.481-4.054 1.659-5.101 4.538-1.1 3.02.504 6.029 1.376 7.666 1.955 3.66 4.444 4.646 5.843 6.364 6.276 7.701-5.55 18.6-2.736 35.99.602 3.731 2.095 7.118 3.065 10.74 1.259 4.707-.696 5.924-1.012 10.157-.195 2.586-.312 5.404.573 7.76z"
      ></path>
      <path
        fill="#5C7479"
        d="M583.209 551.155l2.932 6.399c-.13.547-.43 1.084-1.019 1.754-3.113 3.549-11.543.719-13.56.042-7.408-2.486-8.345-5.557-15.015-7.909-4.581-1.617-8.703-1.777-11.368-1.682.316-.651.866-1.679 1.877-2.587 1.439-1.298 3.882-2.596 7.884-2.606 8.007-.023 28.269 6.589 28.269 6.589z"
      ></path>
      <path
        fill="#fff"
        d="M557.871 581.028s-13.602 5.229-13.593-3.686c0-.29 11.183-36.348 11.183-36.348l16.01 8.657-13.6 31.377z"
      ></path>
      <path
        fill="#000"
        d="M545.169 577.449c.017 1.572.508 2.668 1.494 3.345 2.603 1.789 8.182.368 10.541-.469l13.125-30.28-14.342-7.753c-3.169 10.226-10.349 33.443-10.818 35.157zm5.105 5.99c-1.695 0-3.354-.306-4.62-1.178-1.506-1.038-2.271-2.69-2.268-4.919.004-.179.004-.423 11.219-36.612a.867.867 0 01.514-.559.902.902 0 01.761.039l16.01 8.661c.407.218.58.712.397 1.135l-13.603 31.377a.882.882 0 01-.497.475c-.238.094-4.165 1.581-7.913 1.581z"
      ></path>
      <path
        fill="#5C7479"
        d="M559.547 590.356h-33.703a1.506 1.506 0 01-1.497-1.698l.254-1.917a7.438 7.438 0 013.465-3.966l18.278-9.809s5.899 1.253 5.391 7.431c0 0 3.97-3.81 7.63-1.139 0 0 1.425 4.75 1.308 10.002a1.122 1.122 0 01-1.126 1.096z"
      ></path>
      <path
        fill="#000"
        d="M535.263 584.337a.891.891 0 01-.133-1.77c8.423-1.321 13.554-5.84 13.602-5.885a.894.894 0 011.26.068.888.888 0 01-.069 1.256c-.218.198-5.547 4.912-14.52 6.321a.853.853 0 01-.14.01zM559.488 589.068H525.58a.888.888 0 01-.888-.892.89.89 0 01.888-.891h33.908c.488 0 .885.4.885.891a.888.888 0 01-.885.892z"
      ></path>
      <path
        fill="#5C7479"
        d="M629.757 560.665c2.977-.661 8.687-1.926 9.95-5.827.956-2.951-1.09-6.026-1.556-6.725-5.758-8.648-24.834-11.092-35.635-2.626-5.697 4.467-9.556 12.334-7.538 14.846 1.226 1.526 3.806.065 10.121-.492 11.788-1.034 14.8 3.003 24.658.824z"
      ></path>
      <path
        fill="#fff"
        d="M628.105 544.563c.471.303-5.684 37.835-5.684 37.835l-12.302 1.5.54-36.16 17.446-3.175z"
      ></path>
      <path
        fill="#000"
        d="M611.537 548.484l-.517 34.405 10.626-1.295c2.115-12.916 5.056-31.471 5.528-35.954l-15.637 2.844zm-1.425 36.302a.892.892 0 01-.888-.901l.54-36.16a.895.895 0 01.732-.862l17.446-3.172a.886.886 0 01.634.124c.686.432.791.501-2.261 19.918-1.468 9.314-3.006 18.714-3.019 18.808a.899.899 0 01-.775.739l-12.301 1.5c-.036.003-.069.006-.108.006z"
      ></path>
      <path
        fill="#5C7479"
        d="M590.185 590.356h33.853s1.249-8.397-.7-12.132l-1.054 1.181c-.55.615-1.49.66-2.099.114-1.346-1.211-3.709-3.179-5.752-4.109-3.962-1.809-17.227 7.151-22.309 10.193a3.977 3.977 0 00-1.939 3.419v1.334z"
      ></path>
      <path
        fill="#000"
        d="M623.325 588.609h-31.559a.89.89 0 110-1.78h31.559a.89.89 0 010 1.78zM605.974 584.565a.884.884 0 01-.797-.491.888.888 0 01.391-1.194l9.764-4.926a.891.891 0 01.803 1.591l-9.76 4.925a.863.863 0 01-.401.095zM545.182 549.759c6.504-20.64 13.073-41.261 19.707-61.872a11170.308 11170.308 0 0127.141-83.208c14.217 1.669 45.968-9.12 42.656 5-1.643 6.999-1.493 8.547-1.317 11.153 1.373 20.354 3.507 34.904 3.435 39.992-.058 4.516-.234 18.077.069 36.615.052 3.026.136 6.257.273 10.148.699 19.589 2.007 24.365 2.111 40.321.017 2.241.01 4.415-.019 6.529-7.239-1.421-15.5-1.812-24.271.166-6.927 1.562-7.711 3.153-13.951 3.996a36.465 36.465 0 01-5.798.331c5.121-68.18 4.744-83.813 4.744-83.813a907.886 907.886 0 01-7.356 47.335 907.291 907.291 0 01-7.083 34.894c-7.389-2.18-13.864-6.006-22.684-7.633-4.607-.846-10.489-1.21-17.657.046z"
      ></path>
      <path
        fill="#CADDFF"
        d="M599.968 474.863h-.029a.896.896 0 01-.866-.914l.898-32.958-3.959-2.726a.889.889 0 111.009-1.464l4.359 2.999a.886.886 0 01.384.758l-.908 33.44a.89.89 0 01-.888.865z"
      ></path>
      <path
        fill="#5C7479"
        d="M636.782 374.864l17.487-19.668c2.73 8.619 10.669 29.633 7.168 54.61-2.681 19.108-23.347 31.185-28.927 37.396-1.379-1.545-2.525-3.338-3.481-5.251 1.63-2.932 4.073-7.32 7.06-12.659 5.716-10.22 6.849-12.165 7.333-13.083 3.309-6.292 4.236-17.917-6.64-41.345z"
      ></path>
      <path
        fill="#5C7479"
        d="M636.908 416.453c-4.057 2.792-7.004-.045-24.167-2.954-14.055-2.378-19.97-1.812-24.134-7.002-2.297-2.863-3.003-6.152-3.231-8.501a1857.197 1857.197 0 004.435-21.655 1871.538 1871.538 0 005.671-29.776 130.29 130.29 0 0124.925.205c8.397.875 14.751 2.372 17.351 3.022 6.357 1.594 11.81 3.517 16.511 5.404l-14.319 20.751c1.513 4.819 3.316 12.552 2.707 22.052-.52 8.069-.976 15.162-5.749 18.454z"
      ></path>
      <path
        fill="#5C7479"
        d="M560.178 344.938a35.814 35.814 0 004.369 9.809c2.258 3.504 7.064 10.971 12.887 11.147 6.013.182 10.405-12.578 18.044-19.326.986.898 6.667 9.669 5.037 20.367-1.891 12.38-16.382 19.752-26.376 18.093-12.468-2.07-23.741-19.15-19.648-40.471l5.687.381z"
      ></path>
      <path
        fill="#fff"
        d="M561.063 336.362l-3.032-10.083a1.513 1.513 0 00-2.955.589l.436 4.142-4.021 3.218a3.782 3.782 0 00-1.22 4.154l1.34 3.973a5.105 5.105 0 004.594 3.465l1.412.068a5.11 5.11 0 004.988-3.201l.95-2.382a2.793 2.793 0 00-1.718-3.686l-.774-.257z"
      ></path>
      <path
        fill="#000"
        d="M556.583 326.094c-.026 0-.049.003-.072.003a.623.623 0 00-.55.683l.436 4.136a.904.904 0 01-.328.787l-4.022 3.224a2.895 2.895 0 00-.933 3.172l1.34 3.976a4.187 4.187 0 003.794 2.857l1.412.068a4.198 4.198 0 004.115-2.642l.95-2.381a1.874 1.874 0 00-.039-1.504 1.888 1.888 0 00-1.129-1.008l-.774-.257a.868.868 0 01-.573-.589l-3.035-10.083a.62.62 0 00-.592-.442zm1.278 20.692c-.094 0-.192 0-.286-.006l-1.415-.069a5.964 5.964 0 01-5.395-4.07l-1.34-3.972a4.687 4.687 0 011.506-5.131l3.644-2.918-.387-3.654a2.41 2.41 0 012.121-2.636 2.402 2.402 0 012.574 1.696l2.899 9.64.338.11a3.712 3.712 0 012.18 1.943c.433.93.462 1.968.085 2.918l-.957 2.382a6 6 0 01-5.567 3.767z"
      ></path>
      <path
        fill="#fff"
        d="M561.063 336.365l-3.494.446s-.518 2.837 1.685 2.785l1.809-3.231z"
      ></path>
      <path
        fill="#000"
        d="M559.189 340.481c-.976 0-1.561-.433-1.877-.804-.937-1.096-.651-2.834-.615-3.029a.89.89 0 01.761-.725l3.495-.443a.892.892 0 01.998.771.895.895 0 01-.771.996l-2.768.351c.009.345.074.716.253.921.043.055.166.192.567.182.514-.019.901.378.914.872a.885.885 0 01-.866.908h-.091zM555.291 337.767a.892.892 0 01-.878-.738l-.849-5.007a.88.88 0 01.725-1.022.887.887 0 011.028.726l.853 5a.891.891 0 01-.879 1.041zM552.789 338.971a.89.89 0 01-.875-.735l-.722-3.973a.885.885 0 01.719-1.031.884.884 0 011.031.713l.719 3.979a.884.884 0 01-.872 1.047zM588.832 379.318a.898.898 0 01-.872-1.09l3.988-17.52a.887.887 0 011.061-.67c.478.11.781.585.674 1.067l-3.986 17.52a.886.886 0 01-.865.693z"
      ></path>
      <path
        fill="#CADDFF"
        d="M636.034 450.628a.958.958 0 01-.339-.065c-12.783-5.183-11.045-31.894-10.964-33.029a.894.894 0 01.95-.827.896.896 0 01.826.953c-.019.264-1.734 26.555 9.858 31.253a.887.887 0 01.492 1.159.885.885 0 01-.823.556z"
      ></path>
      <path
        fill="#fff"
        d="M609.966 346.057a7.152 7.152 0 005.993-7.424l-.296-5.648c-3.416-.267-6.26-1.891-6.25-2.971.003-.524 1.259-5.658 2.04-7.779-.664-.54-1.467-2.02-.039-2.928 1.425-.908 4.627-6.679 4.627-6.679 3.953-4.588 5.095 1.877 8.049 2.414 2.043.371 3.149 1.613 2.378 4.281-.774 2.668.693 1.328 2.199.57 1.504-.762 2.525-.17 1.705 2.254-.826 2.424-3.836 3.882-3.836 3.882l-1.425 2.791 3.007 13.733a7.695 7.695 0 004.945 5.609l4.138 1.474-21.756 11.368-10.502-14.127 5.023-.82z"
      ></path>
      <path
        fill="#000"
        d="M606.531 347.521l9.178 12.341 19.27-10.073-2.215-.787a8.565 8.565 0 01-5.518-6.26l-3.003-13.733a.905.905 0 01.078-.596l1.425-2.791a.905.905 0 01.403-.397c.026-.013 2.685-1.327 3.381-3.364.296-.878.254-1.266.224-1.393-.107 0-.332.039-.686.218-.254.127-.511.277-.752.417-.901.524-1.753 1.012-2.476.449-.676-.53-.507-1.503-.224-2.476.289-1.009.28-1.77-.029-2.271-.27-.436-.83-.739-1.66-.888-1.369-.251-2.346-1.383-3.208-2.382-.732-.852-1.428-1.656-2.092-1.656-.491 0-1.129.436-1.854 1.259-.648 1.165-3.384 5.967-4.884 6.921-.361.231-.371.432-.377.51-.016.319.247.765.504.976a.891.891 0 01.274.999c-.746 2.011-1.888 6.699-1.982 7.451.234.553 2.443 1.867 5.424 2.098a.896.896 0 01.82.843l.296 5.651a8.023 8.023 0 01-6.738 8.345l-3.579.589zm8.918 14.371a.89.89 0 01-.716-.358l-10.506-14.127a.9.9 0 01-.114-.865.895.895 0 01.684-.543l5.026-.823a6.25 6.25 0 005.248-6.498l-.257-4.89c-3.13-.436-6.305-1.981-6.289-3.784.003-.699 1.142-5.247 1.907-7.548-.423-.523-.739-1.246-.7-1.991.033-.543.27-1.314 1.204-1.91.849-.54 3.039-4.044 4.327-6.36.029-.052.069-.104.104-.15 1.129-1.305 2.196-1.942 3.257-1.942h.003c1.481 0 2.522 1.204 3.443 2.267.699.817 1.428 1.66 2.179 1.793 1.344.247 2.304.82 2.85 1.708.586.944.661 2.187.225 3.699a.442.442 0 00-.016.059c.039-.023.081-.046.117-.065.27-.159.553-.322.842-.465 1.458-.739 2.291-.313 2.662.006.725.628.82 1.751.283 3.325-.787 2.31-3.189 3.8-4.008 4.256l-1.152 2.251 2.934 13.424a6.774 6.774 0 004.376 4.962l4.139 1.474a.887.887 0 01.11 1.626l-21.752 11.371a.907.907 0 01-.41.098z"
      ></path>
      <path
        fill="#000"
        d="M616.89 334.026c-.446 0-.927-.052-1.432-.176a.89.89 0 11.407-1.731c3.039.723 4.347-1.923 4.399-2.036a.894.894 0 011.187-.423.895.895 0 01.426 1.181c-.562 1.21-2.258 3.185-4.987 3.185zM540.175 400.342a.894.894 0 01-.64-1.51l2.183-2.277a.887.887 0 011.255-.023.88.88 0 01.027 1.256l-2.184 2.277a.883.883 0 01-.641.277zM532.533 408.303a.902.902 0 01-.615-.247.892.892 0 01-.023-1.262l2.18-2.275a.89.89 0 011.259-.026.885.885 0 01.026 1.256l-2.183 2.281a.888.888 0 01-.644.273zM534.674 400.462a.91.91 0 01-.625-.257l-2.215-2.176a.897.897 0 01-.01-1.263.892.892 0 011.259-.009l2.216 2.176a.894.894 0 01-.625 1.529zM542.433 408.082a.863.863 0 01-.621-.254l-2.219-2.176a.894.894 0 01-.013-1.263.892.892 0 011.259-.009l2.219 2.179a.893.893 0 01.01 1.259.883.883 0 01-.635.264zM285.524 114.177a.889.889 0 01-.641-1.506l2.183-2.278a.89.89 0 011.259-.022.884.884 0 01.026 1.255l-2.183 2.278a.893.893 0 01-.644.273zM277.882 122.138a.882.882 0 01-.615-.247.885.885 0 01-.026-1.256l2.183-2.277a.886.886 0 011.259-.026.885.885 0 01.026 1.256l-2.183 2.277a.893.893 0 01-.644.273zM280.026 114.301a.89.89 0 01-.625-.257l-2.219-2.177a.893.893 0 01-.01-1.259.89.89 0 011.259-.013l2.216 2.177a.892.892 0 01-.621 1.529zM287.785 121.92a.884.884 0 01-.624-.253l-2.219-2.18a.89.89 0 011.249-1.269l2.216 2.177a.89.89 0 01-.622 1.525zM648.205 147.773a.888.888 0 01-.888-.875l-.069-3.15a.888.888 0 01.869-.908c.478-.032.898.378.908.872l.071 3.147a.893.893 0 01-.875.911c-.003.003-.01.003-.016.003zM648.439 158.796a.885.885 0 01-.885-.869l-.072-3.149a.891.891 0 01.872-.911c.485-.033.898.377.908.871l.072 3.15a.892.892 0 01-.872.908h-.023zM641.291 151.762a.89.89 0 01-.891-.882c0-.491.393-.892.885-.898l3.11-.026h.006c.492 0 .889.397.889.885a.886.886 0 01-.882.898l-3.107.023h-.01zM652.177 151.677a.889.889 0 01-.888-.885.896.896 0 01.885-.898l3.107-.023h.007c.491 0 .888.394.888.885a.887.887 0 01-.879.898l-3.11.023h-.01z"
      ></path>
    </svg>
  )
}

export default MarketingGraphic