import React from 'react'

const ArrowLeftCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      fill="none"
      viewBox="0 0 78 78"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M39 6.5C21.05 6.5 6.5 21.05 6.5 39S21.05 71.5 39 71.5 71.5 56.95 71.5 39 56.95 6.5 39 6.5z"
      ></path>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M39 26L26 39l13 13M52 39H26"
      ></path>
    </svg>
  )
}

export default ArrowLeftCircle