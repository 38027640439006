import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ReasonSingle = ({
  number,
  title,
  traditional,
  aws,
  reason2,
  reason3,
  reason4,
}) => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.innerWidth < 1024 ? setIsMobile(true) : setIsMobile(false);
    }

    return () => {};
  }, []);

  const trad1st = t(traditional + ".one");
  const trad2nd = t(traditional + ".two");
  const aws1st = t(aws + ".one");
  const aws2nd = t(aws + ".two");
  const aws3rd = t(aws + ".three");

  return (
    <div className="flex flex-col gap-y-4 lg:gap-y-12">
      <div className="title text-theme-teal theme-h2 flex items-center gap-x-6">
        <div
          className={`theme-num text-theme-white ${isMobile ? "" : "hidden"}`}
        >
          {number}
        </div>
        {title}
      </div>
      <div className="traditional flex flex-col gap-y-2 lg:gap-y-7">
        <div className="theme-h4 text-gray-400"> {t("traditional_provider")}</div>
        <div className="theme-p2 text-gray-400">
          <li dangerouslySetInnerHTML={{ __html: trad1st }}></li>
          <li
            className={`${reason4 ? "hidden" : ""}`}
            dangerouslySetInnerHTML={{ __html: trad2nd }}
          ></li>
          <div />
        </div>
      </div>
      <div className="aws flex flex-col gap-y-2 lg:gap-y-7">
        <div className="theme-h4"> {t("aws_serverless")}</div>
        <div className="theme-p2">
          <li dangerouslySetInnerHTML={{ __html: aws1st }}></li>
          <li className={`${reason2 ? "hidden" : ""}`} dangerouslySetInnerHTML={{ __html: aws2nd }}></li>
          <li
            className={`${reason3 ? "" : "hidden"}`}
            dangerouslySetInnerHTML={{ __html: aws3rd }}
          ></li>
        </div>
      </div>
    </div>
  );
};

export default ReasonSingle;
