import React from 'react'

const ArrowRightCircle = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    fill="none"
    viewBox="0 0 78 78"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M39 71.5c17.95 0 32.5-14.55 32.5-32.5S56.95 6.5 39 6.5 6.5 21.05 6.5 39 21.05 71.5 39 71.5z"
    ></path>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M39 52l13-13-13-13M26 39h26"
    ></path>
  </svg>
  )
}

export default ArrowRightCircle